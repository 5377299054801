import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 26 }} spin />;

const Loader = () => {
  return (
      <div className="loader">
        <Spin tip="Loading" size="large" indicator={antIcon} >
          <div className="content"/>
        </Spin>
      </div>
  );
};

export default Loader;