import React from "react";
import { Modal, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import PropsType from "prop-types";
import { useParams } from "react-router-dom";

const DeleteModel = ({ cancelFunc, open, okFunc, content }) => {
  const params = useParams();
  return (
    <div>
      <Modal onCancel={cancelFunc} title="" centered open={open} onOk={okFunc}>
        <Typography.Title className=""><WarningOutlined/></Typography.Title>
        {params?.projectId ? (
          <div className="d-flex">
            <div className="">
              There are {content?.testcases?.length} test cases in{" "}
              {content?.name} !
            </div>
            {/* <div className="ml-8 mt-10 f-w-800"> {content?.testcases?.length}</div> */}
          </div>
        ) : (
          <div className="d-flex">
            <div className="">
              This project may contain TestSuites and TestCases !
            </div>
          </div>
        )}
        <div className="d-flex">
          <div className=" ">Are you sure you want to delete </div>
          <div className=" ml-8 mr-4 f-w-800"> {content?.name}?</div>{" "}
        </div>
      </Modal>
    </div>
  );
};

DeleteModel.propTypes = {
  cancelFunc: PropsType.func,
  open: PropsType.bool,
  okFunc: PropsType.func,
  name: PropsType.string,
};

export default React.memo(DeleteModel);
