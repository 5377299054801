import React, { useState, useEffect } from "react";
import { Modal, Input, Typography, Button } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { messageShow } from "../../utils/shared.service";
import { updateProjectAPI } from "../../reducer/projectSlice";

const EditProjectModel = ({ open, editValues, cancelFunc }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      editProject: editValues.editValue,
    },
  });
  const dispatch = useDispatch();
  // const user_id = localStorage.getItem("user-id");
  // const userId = JSON.parse(user_id);

  const cancelModel = () => {
    cancelFunc();
    reset();
  };

  const editProject = (data) => {
    const updateProject = {
      id: editValues.editId,
      name: data.editProject,
    };
    dispatch(updateProjectAPI(updateProject, messageShow.EDIT));
    setIsLoading(false);
    cancelModel();
  };
  useEffect(() => {
    if(editValues.editModel){
		setValue("editProject", editValues?.editValue, "");
	}
  }, [editValues]);
  const submit = (values) => {
    editProject(values);
  };

  return (
    <form id="EditProject" onSubmit={handleSubmit(submit)}>
      <Modal
        onCancel={cancelModel}
        title="Edit project"
        centered
        open={open}
        footer={[
          <Button key="cancel" onClick={cancelFunc}>
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            form="EditProject"
            key="edit"
            htmlType="submit"
            className="btn-primary"
          >
            Edit
          </Button>,
        ]}
      >
        <label className="f-14 f-w-500 l-h-20 txt-text-teritary">
          Project Name
        </label>
        <Controller
          name="editProject"
          control={control}
          rules={{ required: "Please enter Project Name" }}
          render={({ field }) => (
            <Input
              style={{ marginTop: "0.5rem" }}
              autoFocus
              size="large"
              //   placeholder="Ex: Block chain project"
              value={editValues?.editValue}
              {...field}
            />
          )}
        />
        <Typography.Text type="danger">
          {errors.addTestSuite?.message}
        </Typography.Text>
      </Modal>
    </form>
  );
};

EditProjectModel.propTypes = {
  open: PropTypes.bool,
  editValues: PropTypes.object,
  cancelFunc: PropTypes.func.isRequired,
  //   projectId: PropTypes.string,
};

EditProjectModel.defaultProps = {
  open: true,
};

export default React.memo(EditProjectModel);
