import { createSlice } from "@reduxjs/toolkit";

import { warning } from "../utils/shared.service";
import {
  addProjectApi,
  deleteProjectApi,
  getProjectById,
  getProjectsApi,
  searchProjectsApi,
  updateProjectApi,
} from "../controllers/projects";
import { addMembers } from "./memberSlice";
const initialState = {
  projectsArr: [],
  project: {},
  order:'',
  error: "",
  showError: false,
  isLoading: false,
  testSuite:{}
};

export const PROJECT_KEY = "project";

export const projectSlice = createSlice({
  name: PROJECT_KEY,
  initialState,

  reducers: {
    updateLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setOrder: (state,action) => {
      state.order=action.payload;
    },
    getProjectDataSuccess: (state, action) => {
      state.projectsArr = action.payload;
    },
    getProjectDataFailure: (state, action) => {
      state.isLoading = action.payload;
      state.error = "true";
    },
    createProject: (state, action) => {
      state.push(action.payload);
    },
    getParticularProjects: (state, action) => {
      state.project = Object.assign(action.payload);
    },
    deleteProject: (state, action) => {
      const projectId = action.payload;
      const updatedProjectsArr = state.projectsArr.filter(
        (project) => project.id !== projectId
      );
      state.projectsArr = updatedProjectsArr;
    },
  },
});

export const {
  updateLoader,
  getProjectDataSuccess,
  getProjectDataFailure,
  getParticularProjects,
  deleteProject,
  setOrder,
} = projectSlice.actions;

export const projectReducer = projectSlice.reducer;

export const projectStateSelector = (state) => state.projects;

export const getProjectData = (order) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
        const modifiedData = await getProjectsApi(order);
        dispatch(getProjectDataSuccess(modifiedData));
        dispatch(updateLoader(false));
    } catch (err) {
      console.log("errors", err.data);
      dispatch(getProjectDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};
export const searchProjectAPI = (search) => {
  return async (dispatch) => {
    try {
      const modifiedData = await searchProjectsApi(search);
      dispatch(getProjectDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      console.log("errors", err.data);
      dispatch(getProjectDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};
export const createProjectAPI = (params) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      await addProjectApi(params);
      const modifiedData = await getProjectsApi('');
      dispatch(getProjectDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getProjectDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};

export const deleteProjectAPI = (params) => {
  return async (dispatch) => {
    try {
      // console.log('Slice:' + params.id);
      dispatch(updateLoader(true));
      await deleteProjectApi(params);
      const modifiedData = await getProjectsApi('');
      // dispatch(deleteProject(modifiedData));
      dispatch(getProjectDataSuccess(modifiedData));

      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getProjectDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};

export const updateProjectAPI = (params, message) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      await updateProjectApi(params);
      const modifiedData = await getProjectsApi('');
      dispatch(getProjectDataSuccess(modifiedData));
      dispatch(getParticularProject(params.id));
      warning(message, "success");
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getProjectDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};

export const getParticularProject = (id) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      // console.log(id);
      const details = await getProjectById(id);
      dispatch(getParticularProjects(details));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getProjectDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};
