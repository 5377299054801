import React from 'react';
import { Typography, Button } from 'antd';
import { RiAddLine } from 'react-icons/ri';
import PropType from 'prop-types';

const TitleCard = ({ title }) => {
  return (
    <div>
      <Typography.Title level={4}>{title}</Typography.Title>
    </div>
  );
};

TitleCard.propTypes = {
  title: PropType.string.isRequired,
};

TitleCard.defaultProps = {
  title: 'Add Project',
};

TitleCard.Button = ({ btnName, onClick, isLoading }) => {
  return (
		<Button
			size="medium"
			className="d-flex align-center txt-white btn-primary f-14 f-w-600 l-h-20"
			icon={<RiAddLine className='f-20 mr-4' />}
			onClick={onClick}
      disabled={isLoading}
		>
			{btnName}
		</Button>
	);
};

TitleCard.Button.displayName = 'TitleCard.Button';

TitleCard.Button.propTypes = {
  btnName: PropType.string.isRequired,
  onClick: PropType.func.isRequired,
  isLoading: PropType.bool,
  // color: PropType.string.isRequired
};

export default TitleCard;
