import React from 'react';
import { Input, Typography } from 'antd';
import PropTypes from 'prop-types';
import { AiOutlineSearch } from 'react-icons/ai';

const SearchInput = ({ onSearch, title }) => {
  const searchProject = (value) => {
    onSearch(value);
  };

  return (
		<>
			<Typography>{title}</Typography>
      <div className="search-project">
			<Input
				placeholder="Search project"
				allowClear
				size="medium"
				suffix=""
				prefix={<AiOutlineSearch />}
				onChange={(e) => searchProject(e.target.value)}
				// onPressEnter=
			/>
      </div>
		</>
	);
};

SearchInput.propTypes = {
  onSearch: PropTypes.func,
  title: PropTypes.string,
};

export default React.memo(SearchInput);
