import React from 'react';
import { Typography, Divider } from 'antd';

const Statistics = () => {
  return (
    <div>
      <Typography.Title level={5}>Project statistics</Typography.Title>
      <Divider />
      <Typography className='text-center'>Working on...</Typography>
    </div>
  );
};

export default React.memo(Statistics);
