import { createSlice } from "@reduxjs/toolkit";
// import {
//   getTestSuites,
//   addTestSuite,
//   updateTestSuiteByDocId,
//   // getTestSuitesDesc,
// } from '../apis/testSuites.api';
import { warning } from "../utils/shared.service";
import get from "lodash/get";
import {
  addTestCaseApi,
  getParticularTestCase,
  getTestCaseApi,
  getTagsApi,
  updateCaseApi,
  deleteCaseApi,
  searchTestCaseApi,
} from "../controllers/testcase";

const initialState = {
  testCasesArr: [],
  testCase: {},
  tagsArr: [],
  order: "",
  error: "",
  showError: false,
  isLoading: false,
};

export const TESTCASE_KEY = "testCase";

export const testCaseSlice = createSlice({
  name: TESTCASE_KEY,
  initialState,

  reducers: {
    updateLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    getTestCaseDataSuccess: (state, action) => {
      state.testCasesArr = action.payload;
    },
    getTestCaseDataFailure: (state, action) => {
      state.isLoading = action.payload;
      state.error = "true";
    },
    getParticularTestCaseDataSuccess: (state, action) => {
      state.testCase = action.payload;
    },
    getParticularTestCaseDataFailure: (state, action) => {
      state.isLoading = action.payload;
      state.error = "true";
    },
    getTagDataSuccess: (state, action) => {
      state.tagsArr = action.payload;
    },
    getTagDataFailure: (state, action) => {
      state.isLoading = action.payload;
      state.error = "true";
    },
    createTestCase: (state, action) => {
      state.isLoading = action.payload;
    },
    deleteTestCase: (state, action) => {
      state.testCase = action.payload;
    },
  },
});

export const {
  updateLoader,
  getTestCaseDataSuccess,
  getTestCaseDataFailure,
  getTagDataFailure,
  getParticularTestCaseDataFailure,
  getParticularTestCaseDataSuccess,
  getTagDataSuccess,
  deleteTestCase,
  setOrder,
} = testCaseSlice.actions;

export const testCaseReducer = testCaseSlice.reducer;

// export const testCaseStateSelector = (state) => state.testCase;

export const getTestCaseData = (suiteId, projectId, order) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      const modifiedData = await getTestCaseApi(suiteId, projectId, order);

      dispatch(getTestCaseDataSuccess(modifiedData));
      // console.log("Hello"+state.testCasesArr);
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestCaseDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};
export const searchTestcaseData = (suiteId, projectId, search) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      const modifiedData = await searchTestCaseApi(suiteId, projectId, search);
      dispatch(getTestCaseDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestCaseDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};
export const getTagData = (projectId) => {
  return async (dispatch) => {
    try {
      // dispatch(updateLoader(true));
      const modifiedData = await getTagsApi(projectId);
      dispatch(getTagDataSuccess(modifiedData));
      // console.log("Hello"+state.testCasesArr);
      // dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTagDataFailure(get(err, "data")));
      // dispatch(updateLoader(false));
    }
  };
};

export const getTestCaseDataByTCId = (testCaseId, suiteId, projectId) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      const modifiedData = await getParticularTestCase(
        testCaseId,
        projectId,
        suiteId
      );
      dispatch(getParticularTestCaseDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getParticularTestCaseDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};

export const createTestCaseAPI = (params) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      const response = await addTestCaseApi(params);
      // const response = await getTestSuites(params.projectId,'ASC');
      const { suiteId, projectId } = params;
      const modifiedData = await getTestCaseApi(suiteId, projectId, "");
      dispatch(getTestCaseDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestCaseDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};

export const updateTestCaseAPI = (params, message) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      await updateCaseApi(params);
      warning(message, "success");
      const modifiedData = await getTestCaseApi(
        params.suiteId,
        params.projectId,
        ""
      );
      const modifiedTestCase = await getParticularTestCase(
        params.suiteId,
        params.projectId,
        params
      );
      dispatch(getParticularTestCaseDataSuccess(modifiedTestCase));

      dispatch(getTestCaseDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestCaseDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};

export const closeTestCaseDetails = () => {
  return async (dispatch) => {
    dispatch(getParticularTestCaseDataSuccess(null));
  };
};

export const deleteTestCaseAPI = (testCase, suiteId, projectId, message) => {
  return async (dispatch) => {
    try {
      // console.log(id);
      dispatch(updateLoader(true));
      await deleteCaseApi({ id: testCase.id, projectId, suiteId });
      warning(message, "success");
      dispatch(deleteTestCase({}));
      // console.log('DeleteSuite project id:' + projectId);
      const modifiedData = await getTestCaseApi(suiteId, projectId, "");
      dispatch(getTestCaseDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestCaseDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};
