import React, { useEffect, useState } from "react";
import { Modal, Input, Typography, Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

// import { Link } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import TagsInput from "../Tags";
import FileUploader from "../FileUpload/index";
import {
  createTestCaseAPI,
  updateTestCaseAPI,
} from "../../reducer/testcaseSlice";
import { messageShowCase } from "../../utils/shared.service";
import { isEmpty } from "lodash";
import { addTag, removeAllTags } from "../../reducer/tagsSlice";
import { addedFile, deleteFiles } from "../../reducer/fileSlice";
const { TextArea } = Input;

const TestCaseModel = ({
  open,
  // editValues,
  cancelFunc,
  mode,
  projectId,
  suiteId,
}) => {
  const tags = useSelector((state) => state.tags);
  const files = useSelector((state) => state.files);
  const testCase = useSelector((state) => state?.testCase?.testCase);
  const [isLoading, setIsLoading] = useState(false);
  const [testSteps, setTestSteps] = useState([{ placeholder: 1, value: "" }]);
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (!isEmpty(testCase) && mode === "Edit") {
      setTestSteps([]);
      setValue("addTestCaseName", testCase?.testCaseName, "");
      setValue("addTestCaseDescription", testCase?.description, "");
      setValue("addExpectedResult", testCase?.result, "");
      const tags = testCase?.tagTestCases?.map((item) => ({
        id: item?.TagId,
        name: item?.Tagid?.name || "",
      }));
      tags?.map((item) => {
        dispatch(addTag(item));
      });

      dispatch(addedFile(testCase?.file));
      const steps = JSON?.parse(testCase?.testSteps);
      const a = steps?.map((val, index) => {
        const values = {
          placeholder: index++,
          value: val,
        };
        return values;
      });

      setTestSteps([...a]);
      // setValue("addTestSteps",a,[]);
      // dispatch(addTag(testCase?.tagTestCases))
      // dispatch(addFile(testCase?.file))
    }
  }, [testCase]);

  const dispatch = useDispatch();
  const user_id = localStorage.getItem("user-id");
  const userId = JSON.parse(user_id);
  // const [expanded, setExpanded] = useState(false);

  const addTestStep = () => {
    const newStepNumber = testSteps.length + 1;
    setTestSteps([
      ...testSteps,
      { placeholder: newStepNumber, value: "" },
    ]);
  };
  const removeTestStep = (index) => {
  const updatedTestSteps = testSteps.reduce((acc, step, i) => {
    if (i === index) return acc;
    return [...acc, { ...step, placeholder: acc.length + 1 }];
  }, []);
  setTestSteps(updatedTestSteps);
};
  const handleTestStepChange = (index, value) => {
    const updatedTestSteps = [...testSteps];
    updatedTestSteps[index].value = value;
    setTestSteps(updatedTestSteps);
  };
  // const toggleExpand = () => {
  //   setExpanded(!expanded);
  // };
  const cancelModel = () => {
    cancelFunc();
    dispatch(deleteFiles());
    dispatch(removeAllTags());
    reset();
  };
  const revisedSteps = () => {
    const revisedSteps = testSteps?.map((val) => {
      return val.value;
    });
    return revisedSteps;
  };
  const addTestCase = (data) => {
    const createTestCase = {
      name: data.addTestCaseName,
      description: data.addTestCaseDescription,
      steps: JSON.stringify(revisedSteps()),
      expectedResult: data.addExpectedResult,
      tags: tags,
      file: files,
      createdAt: new Date().toString(),
      createdBy: userId,
      updatedBy: false,
      deletedBy: false,
      projectId,
      suiteId,
    };
    dispatch(createTestCaseAPI(createTestCase));
    setIsLoading(false);
    cancelModel();
  };

  const editTestCase = (data) => {
    const updateTestCase = {
      id: testCase.id,
      name: data.addTestCaseName,
      description: data.addTestCaseDescription,
      steps: JSON.stringify(revisedSteps(data)),
      expectedResult: data.addExpectedResult,
      tags: tags,
      file: files,
      suiteId: testCase.testSuiteId,
    };
    dispatch(
      updateTestCaseAPI(
        { id: updateTestCase, suiteId, projectId },
        messageShowCase.EDIT
      )
    );
    setIsLoading(false);
    cancelModel();
  };

  // const selectedTags = (tags) => console.log(tags);
  const submit = (values) => {
    setIsLoading(true);
    if (mode === "Add") {
      addTestCase(values);
    } else if (mode === "Edit") {
      editTestCase(values);
    }
  };
  return (
    <form id="CaseModal" onSubmit={handleSubmit(submit)}>
      <Modal
        onCancel={cancelModel}
        title={mode === "Add" ? "Add Test Case" : "Edit Test Case"}
        centered
        width={1000}
        open={open}
        className="modalContainer"
        footer={[
          <Button key="cancel" onClick={cancelModel}>
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            form="CaseModal"
            key={mode === "Add" ? "add" : "edit"}
            htmlType="submit"
            type="primary"
          >
            {mode === "Add" ? "Add" : "Save"}
          </Button>,
        ]}
      >
        <div className="f-w-500 f-14 txt-grey-drak mb-8">
          <label>Test Case Name</label>
        </div>
        <Controller
          name="addTestCaseName"
          control={control}
          rules={{ required: "Please enter test case name" }}
          render={({ field }) => (
            <Input
              style={{ marginTop: "0.5rem" }}
              autoFocus
              size="large"
              maxLength={50}
              value={testCase?.name}
              placeholder="Ex: Login with user"
              {...field}
            />
          )}
        />

        <Typography.Text type="danger">
          {errors.addTestCaseName?.message}
          <br />
        </Typography.Text>
        <div className="f-w-500 f-14 txt-grey-drak mb-8">
          <label>Description*</label>
        </div>
        <Controller
          name="addTestCaseDescription"
          control={control}
          rules={{ required: "Please enter description" }}
          render={({ field }) => (
            <TextArea
              rows={5}
              placeholder="Ex: Write description"
              // value={}
              {...field}
              maxLength={256}
            />
          )}
        />
        <Typography.Text type="danger">
          {errors.addTestCaseDescription?.message}
          <br />
        </Typography.Text>
        <div className="f-w-500 f-14 txt-grey-drak mb-8">
          <label>Test Steps*</label>
        </div>
        <Controller
          name="addTestSteps"
          control={control}
          rules={{
            validate: () => {
              const hasValue = testSteps.some((step) => step.value.length > 0);
              return hasValue || "Please enter Test Steps";
            },
          }}
          defaultValue={testSteps}
          render={() => (
            <div className="mb-10">
              {testSteps.map((testStep, index) => (
                <div className="d-flex justify-between align-center" key={index}>
                  <TextArea
                    rows={1}
                    placeholder={"Step " + testStep.placeholder}
                    value={testStep.value}
                    onChange={(e) =>
                      handleTestStepChange(index, e.target.value)
                    }
                    // {...field}

                  />
                  <CloseCircleOutlined className="ml-4" onClick={() =>removeTestStep(index)} />
                </div>
              ))}
              <button
                className="txt-light-blue-14 bg-white mt-8 border-blue radius-6 p-6"
                onClick={addTestStep}
              >
                + Add Test Step
              </button>
              {errors.addTestSteps && (
                <Typography.Text type="danger">
                  {errors.addTestSteps.message}
                  <br />
                </Typography.Text>
              )}
            </div>
          )}
        />
        <div className="f-w-500 f-14 txt-grey-drak mb-8 mt-20">
          <label>Expected Result*</label>
        </div>
        <Controller
          name="addExpectedResult"
          control={control}
          rules={{ required: "Please enter Expected Result" }}
          render={({ field }) => (
            <Input
              rows={1}
              placeholder="Ex: Login Successful"
              value={testCase?.editValue}
              {...field}
              maxLength={256}
            />
          )}
        />
        <Typography.Text type="danger">
          {errors.addExpectedResult?.message}
          <br />
        </Typography.Text>
        <div className="f-w-500 f-14 txt-grey-drak mb-8">
          <label>Tags</label>
        </div>
        <Controller
          name="addTags"
          control={control}
          render={({ field }) => <TagsInput />}
        />
        <div className="f-w-500 f-14 txt-grey-drak mb-8 mt-16">
          <label>Attach Files</label>
        </div>
        <Controller
          name="addFiles"
          control={control}
          render={() => <FileUploader />}
        />
      </Modal>
    </form>
  );
};

TestCaseModel.propTypes = {
  open: PropTypes.bool,
  testCase: PropTypes.object,
  cancelFunc: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["Add", "Edit"]).isRequired,
  project: PropTypes.object,
  suite: PropTypes.object,
  projectId: PropTypes.string,
  suiteId: PropTypes.string,
};

TestCaseModel.defaultProps = {
  open: true,
};

export default React.memo(TestCaseModel);
