import instance from '../utils/api';

export const addTestSuiteApi = async (params) => {
  const suite = {
    name: params.name,
  };
  const response = await instance.post(`/project/${params.projectId}/suites`,suite);
  console.log(response.data);
  return response.data;
}; 

export const getTestSuiteApi = async (id, order) => {
  console.log('testSuite:'+id);
  const response = await instance.get(`/project/${id}/suites?order=${order}`);
  console.log(response.data);  
  return response.data;
};  
//need to bring projectId
export const getTestSuiteById = async (id) => {
  const response = await  instance.get(`/project/${id}/suites/${id}`);
  return response.data;
}
export const searchTestSuiteApi = async (id, search) => {
  // console.log('testSuite:'+id);
  const response = await instance.get(`/project/${id}/suites?search=${search}`);
  console.log(response.data);  
  return response.data;
};  

//need to bring projectId
export const updateSuiteApi = async (params,projectId)=>{
  // console.log('Suite ID'+ params.id);
  const suite = {
    name: params.name,
  };
  const response = await instance.put(`/project/${projectId}/suites/${params.id}`,suite);
  // console.log(response.data);  
  return response.data;
};
//need to bring projectId
export const deleteSuiteApi = async (params,projectId) => {
  // console.log(user.deletedBy);
  const response = await instance.delete(`/project/${projectId}/suites/${params.id}`);  
  return response.data;
};