import { createSlice } from "@reduxjs/toolkit";
import { warning } from "../utils/shared.service";
import get from "lodash/get";
import { addTestSuiteApi, deleteSuiteApi, getTestSuiteApi, getTestSuiteById, searchTestSuiteApi, updateSuiteApi } from "../controllers/testsuite";

const initialState = {
  testSuitesArr: [],
  order:'',
  testSuite: {},
  error: "",
  showError: false,
  isLoading: false,
};

export const TESTSUITE_KEY = "testSuite";

export const testSuiteSlice = createSlice({
  name: TESTSUITE_KEY,
  initialState,

  reducers: {
    setOrder: (state, action) => {
      state.order =action.payload;
    },
    updateLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    getTestSuiteDataSuccess: (state, action) => {
      state.testSuitesArr = action.payload;
    },
    getTestSuiteDataFailure: (state, action) => {
      state.isLoading = action.payload;
      state.error = "true";
    },
    getParticularTestSuites: (state, action) => {
      state.testSuite = Object.assign(action.payload);
    },
    createTestSuite: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  updateLoader,
  setOrder,
  getTestSuiteDataSuccess,
  getTestSuiteDataFailure,
  getParticularTestSuites,
} = testSuiteSlice.actions;

export const testSuiteReducer = testSuiteSlice.reducer;

export const testSuiteStateSelector = (state) => state.projects;

export const getTestSuiteData = (projectId, order) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
        const modifiedData = await getTestSuiteApi(projectId, order);
        dispatch(getTestSuiteDataSuccess(modifiedData));
        dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestSuiteDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};

export const searchTestSuite = (projectId, search) => {
  return async (dispatch) => {
    try {
      // dispatch(updateLoader(true));
      const modifiedData = await searchTestSuiteApi(projectId, search);
      dispatch(getTestSuiteDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestSuiteDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};

export const createTestSuiteAPI = (params) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      await addTestSuiteApi(params);
      const response = await getTestSuiteApi(params.projectId, "");
      dispatch(getTestSuiteDataSuccess(response));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestSuiteDataFailure(get(err, "")));
      dispatch(updateLoader(false));
    }
  };
};

export const updateTestSuiteAPI = (params, projectId, message) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      await updateSuiteApi(params,projectId);
      warning(message, "success");
      const modifiedData = await getTestSuiteApi(projectId, "");
      dispatch(getTestSuiteDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestSuiteDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};

export const deleteTestSuiteAPI = (params, projectId, message) => {
  return async (dispatch) => {
    try {
      // console.log(id);
      dispatch(updateLoader(true));
      await deleteSuiteApi(params,projectId);
      warning(message, "success");
      console.log("DeleteSuite project id:" + projectId);
      const modifiedData = await getTestSuiteApi(projectId, "");
      dispatch(getTestSuiteDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestSuiteDataFailure(get(err, "data")));
      dispatch(updateLoader(false));
    }
  };
};

export const getParticularTestSuite =(id) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      // console.log(id);
      const details = await getTestSuiteById(id);
      dispatch(getParticularTestSuites(details));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getTestSuiteDataFailure(err, "data"));
      dispatch(updateLoader(false));
    }
  };
};
