/* eslint-disable react/display-name */
import React, { lazy, Suspense } from 'react';

const loadable = (importFunc) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={'Loading...'}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
