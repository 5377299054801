/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
import { login, signUp } from "../apis/auth.api";
import { getAllUserApi, getUserApi, postApi } from "../controllers/user";
import { warning } from "../utils/shared.service";
// import { setCookie } from '../utils/cookie.service';
const initialState = {
  users: [],
  userToken: {},
  name: '',
  email: "",
  isLoading: false,
  userType: "",
  success: false,
  error: "",
};

export const USER_KEY = "user";

export const userSlice = createSlice({
  name: USER_KEY,
  initialState,
  reducers: {
    addUser: (state, action) => {
      const user = action.payload
      state.users.push(user);
    },
    updateLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    updateSuccess: (state, action) => {
      state.success = action.payload;
    },
    getUsersDataSuccess: (state, action) => {
      state.users = action.payload;
    },
    getUsersDataFailure: (state, action) => {
      state.error = action.payload;
    },

    getUserDataSuccess: (state, action) => {
      state.email = action.payload.email;
      state.userToken = action.payload.response;
      state.userType = action.payload.userType;
      state.name = action.payload.modifiedData.data.name;
      state.success = true;
    },
    getUserDataFailure: (state, action) => {
      state.userToken = action.payload;
    },
    signUpSuccess: (state, action) => {
      state.email = action.payload.email;
      state.userToken = action.payload.response;
      state.userType = action.payload.userType;
      state.name = action.payload.firstName;
    },
    signUpFailure: (state, action) => {
      state.userToken = action.payload;
    },
    removeUser: (state, action) => {
      const filteredList = state.users.filter((user) => {
        return user.id !== action.payload.id;
      });
      state.users = filteredList;
    },
  },
});

export const {
  getUserDataSuccess,
  updateLoader,
  updateSuccess,
  getUserDataFailure,
  signUpFailure,
  signUpSuccess,
  removeUser,
  clearState,
  getUsersDataSuccess,
  addUser,
  getUsersDataFailure,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
// export const userSelector = useSelector((state) => state?.user?.users);

export const getUserData = (email, password, userType) => {
  return (dispatch) => {
    dispatch(updateLoader(true));
    login(email, password)
      .then(async(response) => {
        localStorage.setItem("user-id", JSON.stringify(response.uid));
        localStorage.setItem(
          "access-token",
          JSON.stringify(response.accessToken)
        );
        localStorage.setItem(
          "refresh-token",
          JSON.stringify(response.refreshToken)
        );
        // console.log(response);
        const modifiedData = await getUserApi(response.uid, response.refreshToken);
        dispatch(getUserDataSuccess({ response, email, userType, modifiedData }));
        warning("login successfully", "success");
        dispatch(updateSuccess(true));
        dispatch(updateLoader(false));
      })
      .catch((err) => {
        const errMessage = _.get(err, "code", "Something went wrong");
        warning(errMessage, "error");
        dispatch(updateSuccess(false));
        dispatch(updateLoader(false));
      });
  };
};

export const signUpUser = (credentials, userType) => {
  const { firstName, email, password } = credentials;
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      const response = await signUp(firstName, email, password, userType);
      const uid = response.uid;
      localStorage.setItem("user-id", JSON.stringify(response.uid));
      localStorage.setItem(
        "access-token",
        JSON.stringify(response.accessToken)
      );
      localStorage.setItem(
        "refresh-token",
        JSON.stringify(response.refreshToken)
      );
      postApi(uid, firstName, email, userType, response.refreshToken);
      dispatch(signUpSuccess({ response, email, userType,firstName }));
      // console.log(response);
      dispatch(updateSuccess(true));
      dispatch(updateLoader(false));
    } catch (err) {
      const errMessage = _.get(err, "code", "Something went wrong");
      warning(errMessage, "error");
      dispatch(updateSuccess(false));
      dispatch(updateLoader(false));
    }
  };
};

export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      const data = await getAllUserApi();
      dispatch(getUsersDataSuccess(data));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getUsersDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};

export const logout = () => {
    localStorage.removeItem("access-token");
    // localStorage.removeItem("user-id");
    warning("logout successfull", "success");
  };

// export const signinUserGoogle = (userType) => {
//   const response = signInWithGoogle();
//   return (dispatch) => {
//     try {
//       dispatch(updateLoader(true));
//       const email = response.email;
//       createUser({ role: userType, email});
//       dispatch(signUpSuccess({ response, email, userType }));
//       localStorage.setItem('user-data', JSON.stringify(response));
//       dispatch(updateSuccess(true));
//       dispatch(updateLoader(false));
//     } catch (err) {
//       const errMessage = _.get(err, 'code', 'Something went wrong');
//       warning(errMessage, 'error');
//       dispatch(updateSuccess(false));
//       dispatch(updateLoader(false));
//     }
//   };
// };
