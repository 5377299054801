// import image2 from "../../assets/style/image/code_blocks.png";
// import image3 from "../../assets/style/image/group.png";
// import { BellOutlined } from "@ant-design/icons";
// import { Drawer } from "../../components";
// import { router } from "../../utils/routes";
import React from "react";
import profileicon from "../../assets/images/icons/profile-icon.png";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import { Dropdown } from "antd";
import DropDownMenu from "../DropDown";
import { logout } from "../../reducer/userSlice";
import "../../assets/styles/components/sidenavbar.scss";
import { useDispatch, useSelector } from "react-redux";
const SideNavbar = () => {
  //   const [navbar, setNavbar] = useState({
  //     drawerOpen: false,
  //   });
  const dispatch = useDispatch();
  const userName = useSelector((state) => state?.user?.name);
  const user_id = localStorage.getItem("user-id");
  const userId = JSON.parse(user_id);
  const operation = [
    {
      key: "logout",
      displayName: "Logout",
      value: "LogOut",
    },
  ];
  const handleMenuClick = (e) => {
    logout();
    // navigation(router.default);
    window.location.reload(true);
  };

  return (
    // New Sidebar
    <div className="fixed left-0 h-100 bg-dark-primary w-56 py-16 px-8 index-2">
      <div>
        <div className="d-flex justify-center">
          <Avatar>
            {userId
              .toUpperCase()
              .split(" ")
              .map((word) => word.charAt(0))
              .join("")}
          </Avatar>
        </div>
        <div className="sidenavbar-content d-flex justify-between flex-column mt-40">
          <div>
            <div className="p-10 d-flex justify-center cursor-pointer txt-grey-05 txt-hover-white bg-hover-grey-04 radius-4">
              <span class="material-symbols-outlined">code_blocks</span>
            </div>
            <div className="p-10 mt-8 d-flex justify-center cursor-pointer txt-grey-05 txt-hover-white bg-hover-grey-04 radius-4">
              <span class="material-symbols-outlined">group</span>
            </div>
            <div className="p-10 mt-8 d-flex justify-center cursor-pointer txt-grey-05 txt-hover-white bg-hover-grey-04 radius-4">
              <span class="material-symbols-outlined">notifications</span>
            </div>
          </div>

          <div>
            <Dropdown
              overlay={
                <DropDownMenu onClick={handleMenuClick} options={operation} />
              }
              trigger={["click"]}
            >
              <Avatar>
                {userId
                  .toUpperCase()
                  ?.split(" ")
                  .map((word) => word.charAt(1))
                  .join("")}
              </Avatar>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SideNavbar);
