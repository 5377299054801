import React from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { router } from '../../utils/routes';

const NavigationTab = ({ list, defaultKey }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onChange = (key) => {
    navigate(`${router.projects}/${location.pathname.split('/')[2]}/${key}`);
  };

  return (
    <Tabs defaultActiveKey={defaultKey} onChange={onChange} items={list} />
  );
};

NavigationTab.propTypes = {
  list: PropTypes.array,
  defaultKey: PropTypes.string,
};

export default React.memo(NavigationTab);
