import { Tag, Input } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTag, removeTag, setTags } from "../../reducer/tagsSlice";
import { TweenOneGroup } from "rc-tween-one";
const TagInput = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const tags = useSelector((state) => state.tags);
  const [isTagInputVisible, setTagInputVisible] = useState(false);
  const tagInputRef = useRef(null);
  const tagList = useSelector((state) => state?.testCase?.tagsArr);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    const filteredOptions = tagList.filter(
      (tag) => tag.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    setAutocompleteOptions(filteredOptions);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && inputValue?.trim() !== "") {
      const enteredTag = { name: inputValue };
      if (tags.find((tag) => tag.name === enteredTag.name)) {
        setInputValue("");
        setTagInputVisible(false);
        return;
      }

      dispatch(addTag(enteredTag));
      setInputValue("");
      setTagInputVisible(false);
    }
  };

  const handleTagRemove = (tag) => {
    dispatch(removeTag(tag));
  };

  const handleTagClick = () => {
    setTagInputVisible(true);
  };

  const handleAutocompleteOptionClick = (tag) => {
    if (
      !tag &&
      !autocompleteOptions.some((option) => option.name === inputValue)
    ) {
      const enteredTag = { name: inputValue };
      dispatch(addTag(enteredTag));
      setAutocompleteOptions([...autocompleteOptions, enteredTag]);
    } else if (tags.find((tagState) => tagState.name === tag.name)) {
      setInputValue("");
      setTagInputVisible(false);
      return;
    } else {
      dispatch(setTags([...tags, { id: tag?.id, name: tag?.name }]));
    }
    setInputValue("");
    setTagInputVisible(false);
  };

  useEffect(() => {
    if (isTagInputVisible && tagInputRef.current) {
      tagInputRef.current.focus();
    }
  }, [isTagInputVisible]);

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        className="bg-grey radius-20 p-4 mt-8 f-14 f-w-500"
        key={tag.name}
        closable
        onClose={() => handleTagRemove(tag)}
      >
        {tag.name}
      </Tag>
    );
    return (
      <span className="d-inline-block" key={tag.name}>
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);

  return (
    <div className="border-light-grey radius-10 ">
      <div className={`${tags.length > 0 ? "expanded" : ""}`}>
        <div className="mb-16">
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: "from",
              duration: 100,
              onComplete: (e) => {
                if (e && e.target) {
                  e.target.style = "";
                }
              },
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        </div>
        <div className="mb-50">
          {isTagInputVisible && (
            <div className="autocomplete-input">
              <Input
                type="text"
                size="small"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                onBlur={handleInputKeyPress}
                ref={tagInputRef}
              />
              <div className="autocomplete-options">
                {autocompleteOptions.map((option) => (
                  <div
                    key={option.id}
                    className="autocomplete-option"
                    onClick={() => {
                      handleAutocompleteOptionClick(option);
                      setTagInputVisible(false);
                    }}
                  >
                    {option.name}
                  </div>
                ))}
                {!autocompleteOptions.some(
                  (option) => option.name === inputValue
                ) &&
                  inputValue.trim() !== "" && (
                    <div
                      className="autocomplete-option add-new-tag"
                      onClick={() => {
                        handleAutocompleteOptionClick();
                        setTagInputVisible(false);
                      }}
                    >
                      Add new tag: {inputValue}
                    </div>
                  )}
              </div>
            </div>
          )}
          {!isTagInputVisible && (
            <div className="tag-input-header">
              <Tag className="ml-8" onClick={handleTagClick}>
                Add New Tag
              </Tag>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TagInput;
