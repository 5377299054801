import { useEffect, useState } from 'react';

const useProtectedRoute = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const user_id = localStorage?.getItem('user-id');
  const access_token = localStorage?.getItem('access-token'); 
  if (!user_id || !access_token) {
    return isAuthorized;
  }
  const userToken = JSON?.parse(access_token);
  const userId = JSON?.parse(user_id);
  useEffect(() => {
    const checkAuthorization = () => {
        setIsAuthorized(userToken && userId);
    };

    checkAuthorization();
  }, [userToken]);
  
  return [isAuthorized];
};

export default useProtectedRoute;
// ||((typeof user_id !=='string') && typeof access_token !=='string' )