import React from 'react';
import { Typography, Divider, Row, Col } from 'antd';
import PropTypes from 'prop-types';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const Dividers = () => (
  <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
);

const Details = ({ title, description }) => {
  return (
    <div className="mt-10">
      <div className="f-26">{title}</div>
      <Typography.Text className="f-16">{description}</Typography.Text>
    </div>
  );
};

const Overview = ({ details }) => {
  return (
    <div className="mb-20" style={{ marginTop: '3rem' }}>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <div className="p-d-card flex-1 bg-color-primary">
            <Typography.Title className="txt-white" level={5}>
              Project name:
            </Typography.Title>
            <Dividers />
            <h4 className="txt-white">
              <strong>{details.project_name}</strong>
            </h4>
          </div>
        </Col>

        <Col span={6}>
          <div className="p-d-card flex-1">
            <Typography.Title className="txt-color-secondary" level={5}>
              Created on:
            </Typography.Title>
            <Dividers />
            <Details
              title={`${new Date(details.created_at).getDate()}th`}
              description={'August 2022'}
            />
          </div>
        </Col>

        <Col span={6}>
          <div className="p-d-card flex-1">
            <Typography.Title className="txt-color-secondary" level={5}>
              Updated on:
            </Typography.Title>
            <Dividers />
            <Details
              title={`${new Date().getDate()}th`}
              description={`${
                monthNames[new Date().getMonth()]
              } ${new Date().getFullYear()}`}
            />
          </div>
        </Col>

        <Col span={6}>
          <div className="p-d-card flex-1">
            <Typography.Title className="txt-color-secondary" level={5}>
              Created by:
            </Typography.Title>
            <Dividers />
            <Details title={'Akash Palani'} description={details.created_by} />
          </div>
        </Col>

        <Col span={6}>
          <div className="p-d-card flex-1">
            <Typography.Title className="txt-color-secondary" level={5}>
              Updated by:
            </Typography.Title>
            <Dividers />
            <Details title={'Vasanth'} description={details.updated_by} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

Details.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

Overview.propTypes = {
  details: PropTypes.object,
};

export default React.memo(Overview);
