// import { Divider } from 'antd';
// import Navbar from '../../components/Navbar/index.js';
import React, { useState } from "react";
import { getProjectData, updateProjectAPI } from "../../reducer/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";

import {
	TitleCard,
	SearchInput,
	ProjectCard,
	AddModel,
	DeleteModel,
} from "../../components";
import { messageShow, getLoggedInUserData } from "../../utils/shared.service";

const dashboard = () => {
	const [project, setProject] = useState({
		search: "",
		openModel: false,
		editValue: "",
		editId: "",
		deleteModel: false,
		deleteId: "",
	});
	const isLoading = useSelector((state) => state.project.isLoading);
	const getProjectSelector = useSelector((state) => state.project.projectsArr);
	const email = get(getLoggedInUserData(), "email", null);

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(getProjectData());
	}, []);

	const searchResult = (value) => {
		setProject((preValue) => ({ ...preValue, search: value }));
	};

	const closeModel = () => {
		setProject({ openModel: false, editValue: "", editId: "" });
	};

	const editProject = (values) => {
		const { project_name, id } = values;
		setProject({ openModel: true, editValue: project_name, editId: id });
	};

	const getDeleteProjectDetails = (value) => {
		const { id } = value;
		setProject((preValue) => ({
			...preValue,
			deleteModel: true,
			deleteId: id,
		}));
	};

	const deleteProject = () => {
		if (project.deleteId !== "") {
			let delProject = {
				updated_at: `${new Date()}`,
				updated_by: email,
				is_deleted: true,
			};
			dispatch(
				updateProjectAPI(delProject, project.deleteId, messageShow.DELETE)
			);
			setProject((preValue) => ({
				...preValue,
				deleteModel: false,
				deleteId: "",
			}));
		}
	};
	return (
		<div>
			<div>
				<SideNavbar />
				<div className="d-flex justify-between align-center py-12 pr-24 pl-70 bg-white sticky top-0">
					<div>
						<h2 className="f-16 f-w-700 l-h-22 txt-text-secondary">Projects</h2>
					</div>
					<div className="d-flex align-center">
						<div>
							<SearchInput onSearch={searchResult} />
						</div>
						<div className="mx-12 bg-white image-40 d-flex justify-center align-center border-light-grey radius-6">
							<BsFilterLeft onClick={() => setIsDescending(!isDescending)} />
						</div>
						<div>
							<AddModel
								open={project.openModel}
								cancelFunc={(values) => closeModel(values)}
								editValues={project}
								okBtnName={"Add"}
								title={"Add Project"}
							/>
						</div>
						<div>
							<TitleCard.Button
								btnName={"Add Project"}
								onClick={() =>
									setProject((preValue) => ({ ...preValue, openModel: true }))
								}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</div>
				<div>
					<ProjectCard
						edit={(item) => editProject(item)}
						deleteProject={(data) => getDeleteProjectDetails(data)}
						projects={getProjectSelector}
						isLoading={isLoading}
						search={project.search}
					/>
				</div>
			</div>
		</div>
	);
};
export default React.memo(dashboard);
