import { useLocation, useNavigate } from "react-router-dom";
import { projectOperation } from "../../utils/constants";
import { EllipsisOutlined, LeftOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import DropDownMenu from "../DropDown";
import NavigationTab from "../NavigationTab";
import { useDispatch, useSelector } from "react-redux";
import { tabList } from "../../utils/constants";
import { useState } from "react";
import DeleteModel from "../DeleteModel";
import EditProjectModel from "../EditModel";
import { deleteProjectAPI } from "../../reducer/projectSlice";
import { messageShow } from "../../utils/shared.service";
import { router } from "../../utils/routes";


const ProjectNavBar = () => {
  const [projectDetail, setProjectDetail] = useState({
    search: "",
    openModel: false,
    editModel: false,
    editValue: "",
    editId: "",
    deleteModel: false,
    deleteId: "",
  });
  const dispatch = useDispatch();
  //   const [operation, setOperation] = useState("");
  const navigate = useNavigate();
  const project = useSelector((state) => state?.project?.project);
  const location = useLocation();
  const handleGoBack = () => {
    window.history.back();
  };

  const editProject = () => {
    setProjectDetail((prevValue) => ({
      ...prevValue,
      editModel: true,
      editValue: project.name,
      editId: project.id,
    }));
  };
  const deleteProject = () => {
    if (projectDetail.deleteId !== "") {
      const projectDelete = {
        id:projectDetail.deleteId
      }
      // console.log('Render'+deleteProject.id);
       dispatch(deleteProjectAPI(projectDelete, messageShow.DELETE));
      setProjectDetail((preValue) => ({
        ...preValue,
        deleteModel: false,
        deleteId: "",
      }));
      navigate(
        `${router.projects}`
      );
    }
  };
  const setDeleteSuite = () => {
    setProjectDetail((preValue) => ({
      ...preValue,
      deleteModel: true,
      deleteId: project?.id,
    }));
    // console.log('ProjectId: ' + value.id);
  };
  const closeModel = () => {
    setProjectDetail({
      ...projectDetail,
      editModel: false,
      editValue: "",
      editId: "",
    });
  };
  const handleMenuClick = (e) => {
    switch (e.key) {
      case "manageMembers":
        //   console.log("Manage Members", item);
        break;
      case "rename":
        editProject();
        break;
      case "deleteProject":
        setDeleteSuite();
        break;
      default:
        break;
    }
  };
  return (
    <div className="bg-white sticky top-0 index-1">
      <div className="pl-70 pt-20">
        <div className="d-flex align-center">
          <LeftOutlined className="mr-8" onClick={handleGoBack} />
          <div className="mr-8">
            <h3 className="f-18 f-w-700 l-h-26 txt-text-secondary">
              {project?.name}
            </h3>
          </div>
          <div className="d-flex">
            <Dropdown
              overlay={
                <DropDownMenu
                  onClick={handleMenuClick}
                  options={projectOperation}
                />
              }
              trigger={["click"]}
            >
              <EllipsisOutlined className="f-20 txt-grey-11" />
            </Dropdown>
          </div>
        </div>
        {/* Tab  */}
        <div className="pt-20">
          {
            <NavigationTab
              list={tabList}
              defaultKey={location.pathname.split("/")[3]}
            />
          }
        </div>
      </div>
      {projectDetail.editModel && (
        <EditProjectModel
          open={projectDetail.editModel}
          cancelFunc={(values) => closeModel(values)}
          editValues={projectDetail}
        />
      )}
      {projectDetail.deleteModel && (
        // <DeleteModel
        //   open={projectDetail.deleteModel}
        //   cancelFunc={() => }
        //   okFunc={deleteProject}
        //   name="Project"
        // />
        <DeleteModel
        open={projectDetail.deleteModel}
        cancelFunc={() =>
          setProjectDetail((preValue) => ({
            ...preValue,
            deleteModel: false,
            deleteId: "",
          }))
        }
        okFunc={deleteProject}
        name="Project"
      />
      )}
    </div>
  );
};

export default ProjectNavBar;
