import { combineReducers } from '@reduxjs/toolkit';
import { passwordSlice, PASSWORD_KEY } from './passwordSlice';
import { userReducer, USER_KEY } from './userSlice';
import { projectReducer, PROJECT_KEY } from './projectSlice';
import { testSuiteReducer, TESTSUITE_KEY } from './testSuitesSlice';
import { testCaseReducer, TESTCASE_KEY } from './testcaseSlice';
import { tagsReducer, TAGS_KEY } from './tagsSlice';
import { filesReducer, FILES_KEY } from './fileSlice';
import { memberReducer, MEMBER_KEY } from './memberSlice';
export const reducer = () => {
  return combineReducers({
    [USER_KEY]: userReducer,
    [PROJECT_KEY]: projectReducer,
    [TESTSUITE_KEY]: testSuiteReducer,
    [PASSWORD_KEY]: passwordSlice.reducer,
    [TESTCASE_KEY]: testCaseReducer,
    [TAGS_KEY]:tagsReducer,
    [FILES_KEY]:filesReducer,
    [MEMBER_KEY]: memberReducer,
  });
};
