import React from 'react';
import { Button } from "antd";
import { Link } from 'react-router-dom';
import "../../assets/styles/components/landing.scss";
import QANavbar from '../../components/Navbar/landingNav.js';
import testprocess from "../../assets/images/qa-test-process-img.png";

const LandingPage = () => {
  return (
		<div>
			<QANavbar />
			<div>
				<div className="container">
					<div className="d-flex align-center justify-evenly landing-banner">
						<div>
							<img
								className="mw-560"
								src={testprocess}
								alt="Maxis QA Test Process"
							/>
						</div>
						<div className="mw-400 w-100">
							<h3 className="txt-text-primary mb-8">Maxis QA</h3>
							<h2 className="f-32 f-w-600 text-secondary mb-16">
								The best tracking tool for QA test process
							</h2>
							<p className="f-14 f-w-400 text-teritary mb-32">
								You can track QA testing process with your teammates using the
								Maxis QA tool by creating your organisation.
							</p>
							<Button type="primary">
								<Link to="/signup">Get Started</Link>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default React.memo(LandingPage);
