import React, { useState } from "react";
// import { Card, Row, Col, Typography, Dropdown } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { TitleCard, Navbar } from "../../components";
import {
  getTestSuiteData,
  searchTestSuite,
  setOrder,
} from "../../reducer/testSuitesSlice";
import AddSuitModel from "../../components/TestSuiteAddModal/suitAddModel";
import { Input } from "antd";
// import { suiteOperation } from "../../utils/constants";
// import { router } from "../../utils/routes";
import { SearchOutlined } from "@ant-design/icons";
// import DropDownMenu from "../../components/DropDown";
import { getParticularProject } from "../../reducer/projectSlice";
import ProjectNavBar from "../../components/ProjectNavbar";
import SuiteCard from "../../components/SuiteCard";
const Suites = () => {
  const [suites, setSuites] = useState({
    search: "",
    openModel: false,
    editValue: "",
    editId: "",
    deleteModel: false,
    deleteId: "",
  });
  const dispatch = useDispatch();
  const order = useSelector((state) => state?.testSuite?.order);
  // const project = useSelector((state)=> state?.project?.project)
  // const isLoading = useSelector((state) => state?.testSuite?.isLoading);
  // const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;

  React.useEffect(() => {
    dispatch(getParticularProject(projectId));
    dispatch(getTestSuiteData(projectId, order));
  }, [projectId, order]);

  const addSuite = () => {
    setSuites((prevValue) => ({ ...prevValue, openModel: true }));
  };

  const closeModel = () => {
    setSuites((prevValue) => ({ ...prevValue, openModel: false, editId: "" }));
  };

  const searchSuites = (search) => {
    if (search.trim().length > 2) {
      dispatch(searchTestSuite(projectId, search));
    } else if (search.length === 0) {
      dispatch(getTestSuiteData(projectId, order));
    }
  };

  const setDeleteSuite = (value) => {
    const { id } = value;
    setSuites((preValue) => ({
      ...preValue,
      deleteModel: true,
      deleteId: id,
    }));
  };

  return (
    <div>
      <div>
        <Navbar />
        <div>
          <ProjectNavBar />
          <div>
            <div className="test-suite-content pt-26 pl-70 pr-20 bg-grey-secondary">
              {/* Tab Contents  */}
              <div className="d-flex justify-between align-center mb-20">
                <div className="suite-title">
                  <TitleCard title="Test suites" />
                </div>
                <div className="d-flex align-center">
                  <div className="search-test-input">
                    <Input
                      placeholder="Search Test Suite"
                      onChange={(e) => searchSuites(e.target.value)}
                      prefix={<SearchOutlined />}
                    />
                  </div>
                  <div className="mx-8">
                    <span className="d-flex align-center justify-center radius-6 bg-white image-36 cursor-pointer">
                      {order === "" || order === "DESC" ? (
                        <SortAscendingOutlined
                          onClick={() => dispatch(setOrder("ASC"))}
                        />
                      ) : (
                        <SortDescendingOutlined
                          onClick={() => dispatch(setOrder("DESC"))}
                        />
                      )}
                    </span>
                  </div>
                  <div>
                    <TitleCard.Button
                      btnName={"Add test suite"}
                      onClick={addSuite}
                    />
                  </div>
                </div>
              </div>
              <div>
                <SuiteCard />
                {suites.openModel && (
                  <AddSuitModel
                    open={suites.openModel}
                    editValues={suites}
                    cancelFunc={(values) => closeModel(values)}
                    mode={suites.editId === "" ? "Add" : "Edit"}
                    projectId={projectId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Suites);
