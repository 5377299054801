export const router = {
  default: '/',
  login:'/login',
  signup: '/signup',
  admin: 'admin',
  adminSignup: '/admin/signup',
  projects: '/projects',
  overview: 'overview',
  suites: 'suites',
  cases: 'cases',
  projectDetails: ':projectId',
  suiteDetails: ':suiteId',
  notFound: '*',
  dashboard:'/dashboard'
};
