import React, { useState, useEffect } from "react";
import { Modal, Input,Form, Typography, Button } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  createTestSuiteAPI,
  updateTestSuiteAPI,
} from "../../reducer/testSuitesSlice";
import { messageShowSuite } from "../../utils/shared.service";
import { isEmpty } from "lodash";
const AddSuitModel = ({ open, editValues, cancelFunc, mode, projectId }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [editVal, setEditVal] = useState(editValues);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const user_id = localStorage.getItem("user-id");
  const userId = JSON.parse(user_id);

  const cancelModel = () => {
    cancelFunc();
    reset();
  };

  useEffect(() => {
    if (!isEmpty(editValues) && mode === "Edit") {
      setValue("addTestSuite",editValues?.editValue,"");
    }
  }, []);

  const addTestSuite = (data) => {
    const createTestSuite = {
      name: data.addTestSuite,
      createdAt: new Date().toString(),
      createdBy: userId,
      updatedBy: false,
      deletedBy: false,
      title: data.addTestSuite,
      is_deleted: false,
      projectId,
    };
    dispatch(createTestSuiteAPI(createTestSuite));
    setIsLoading(false);
    cancelModel();
  };

  const editTestSuite = (data) => {
    const updateTestsuite = {
      id: editValues.editId,
      updatedAt: new Date().toString(),
      updatedBy: userId,
      name: data.addTestSuite,
    };
    dispatch(
      updateTestSuiteAPI(updateTestsuite, projectId, messageShowSuite.EDIT)
    );
    setIsLoading(false);
    cancelModel();
  };

  const submit = (values) => {
    setIsLoading(true);
    if (mode === "Add") {
      addTestSuite(values);
    } else if (mode === "Edit" && editValues.editValue !== values.addTestSuite) {
      editTestSuite(values);
    }
  };

  return (
    <form id="addEditProject" onSubmit={handleSubmit(submit)}>
      <Modal
        onCancel={cancelModel}
        title={mode === "Add" ? "Add Test Suite" : "Edit Test Suite"}
        centered
        width={400}
        height={224}
        open={open}
        footer={[
          <Button
            key="cancel"
            onClick={cancelFunc}
            style={{
              width: "95px",
              height: "40px",
              background: "#FFFFFF",
              color: "black",
              border: "1px solid #E7ECEF",
            }}
          >
            Cancel
          </Button>,
          <Button
            style={{ width: "95px", height: "40px" }}
            loading={isLoading}
            form="addEditProject"
            key={mode === "Add" ? "add" : "edit"}
            htmlType="submit"
            type="primary"
          >
            {mode === "Add" ? "Add" : "Save"}
          </Button>,
        ]}
      >
        <label>Test Suite Name</label>
        <Controller
          name="addTestSuite"
          control={control}
          rules={{ required: "Please enter test suite name" }}
          render={({ field }) => (
            <Input
              style={{ marginTop: "0.5rem" }}
              autoFocus
              size="large"
              value={editValues.editValue}
              placeholder="Ex: Login test suite"
              {...field}
            />
          )}
        />
        <Typography.Text type="danger">
          {errors.addTestSuite?.message}
        </Typography.Text>
      </Modal>
    </form>
  );
};

AddSuitModel.propTypes = {
  open: PropTypes.bool,
  editValues: PropTypes.object,
  cancelFunc: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["Add", "Edit"]).isRequired,
  projectId: PropTypes.string,
};

AddSuitModel.defaultProps = {
  open: true,
};

export default React.memo(AddSuitModel);
