import React, { useEffect, useState } from "react";
import { Card, Typography } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  RightOutlined,
  LeftOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import { TitleCard, Navbar } from "../../components";
import TestCaseModal from "../../components/TestCaseModal";
import DeleteModel from "../../components/DeleteModel";
import {
  getTagData,
  getTestCaseData,
  getTestCaseDataByTCId,
  closeTestCaseDetails,
  deleteTestCaseAPI,
  setOrder,
  searchTestcaseData,
} from "../../reducer/testcaseSlice";
import CaseDetail from "../../components/TestCaseDetail";
import ProjectNavBar from "../../components/ProjectNavbar";
import { getParticularProject } from "../../reducer/projectSlice";
import { messageShowCase } from "../../utils/shared.service";
import Loader from "../../components/Loader";

const TestCases = () => {
  // const [cases, setCases] = useState({
  //   search: "",
  //   openModel: false,
  //   editValue: {},
  //   editId: "",
  //   deleteModel: false,
  //   deleteId: "",
  // });
  const [operation, setOperation] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const dispatch = useDispatch();
  const order = useSelector((state) => state?.testCase?.order);
  const isLoading = useSelector((state) => state?.testCase?.isLoading);
  const { projectId, suiteId } = useParams();

  const getTestCaseList = useSelector((state) => state?.testCase?.testCasesArr);
  const testCase = useSelector((state) => state?.testCase?.testCase);

  useEffect(() => {
    dispatch(getTestCaseData(suiteId, projectId,order));
    dispatch(getParticularProject(projectId));
  }, [order]);

  const addCase = () => {
    // setCases((prevValue) => ({ ...prevValue, openModel: true }));
    setOperation("Add");
  };

  const closeModel = () => {
    // setCases((prevValue) => ({ ...prevValue, openModel: false, editId: "" }));
    setOperation(null);
  };
  const editCase = () => {
    setOperation("Edit");
  };
  const deleteCase = () => {
    setOperation("Delete");
  };
  const deleteTestCase = () => {
    dispatch(
      deleteTestCaseAPI(testCase, suiteId, projectId, messageShowCase.DELETE)
    );
    setOperation(null);
  };
  const handleMenuClick = (e) => {
    switch (e.key) {
      case "manageMembers":
        console.log("Manage Members");
        break;
      case "rename":
        editCase();
        break;
      case "deleteTestCase":
        deleteCase();
        break;
      default:
        break;
    }
  };
  const stepListCreator = (steps) => {
    if (steps) {
      return JSON?.parse(steps);
    } else {
      return [];
    }
  };
  const getTags = () => {
    dispatch(getTagData(projectId));
  };
  const formateDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = new Date(dateString);
    // console.log( 'Hello ' + dateString);
    const formattedDate = date.toLocaleDateString("en-US", options);
    // console.log('Hello ' + formattedDate);
    const [month, day, year] = formattedDate.split(" ");
    const monthAbbreviation = month.substring(0, 3);
    return `${day.substring(0, 2)} ${monthAbbreviation} ${year}`;
  };
  const searchTestCases = (search) => {
    if (search.trim().length > 2) {
      dispatch(searchTestcaseData(suiteId, projectId, search));
    } else if (search.length === 0) {
      dispatch(getTestCaseData(suiteId, projectId, order));
    }
  };
  return (
    <div>
      
      {isLoading && (
        <Loader />
      )}
        <Navbar />
        <ProjectNavBar />
        <div className="pt-8 pl-70 pr-20 bg-grey-secondary">
          <div className="d-flex justify-between align-center mb-10">
            <div className="d-flex justify-between align-center">
              <div className="suite-title">
                <TitleCard title="Test suites" />
              </div>
              <RightOutlined className="ml-8 mr-8" />
            </div>

            <div className="d-flex pt-10 align-center">
              <div className="mr-10">
                <button
                  className="ant-btn border-green txt-text-primary"
                  type="button"
                >
                  Import Test Case
                </button>
              </div>

              <div>
                <TitleCard.Button
                  btnName={"Add Test Case"}
                  onClick={() => {
                    getTags();
                    addCase();
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex p-10">
            <div className="mw-360 bg-white w-100 mr-8">
              <div className="mt-24">Test Cases</div>
              <div className="d-flex mt-16">
                <Input
                  placeholder="Search Test Case"
                  onChange={(e) => searchTestCases(e.target.value)}
                  prefix={<SearchOutlined />}
                />
                <div
                  className="d-flex ml-16 align-center border-light-grey p-6 mr-8"
                >
                  {order === "" || order === "DESC" ? (
                        <SortAscendingOutlined
                          onClick={() => dispatch(setOrder("ASC"))}
                        />
                      ) : (
                        <SortDescendingOutlined
                          onClick={() => dispatch(setOrder("DESC"))}
                        />
                      )}
                </div>
              </div>
              <div className="">
                {getTestCaseList.length ? (
                  getTestCaseList.map((event, index) => (
                    <Card
                      loading={isLoading}
                      actions={[]}
                      className=""
                      key={index}
                    >
                      <div className="d-flex align-center justify-between">
                      <div>
                        <div className="f-14 f-w-600">
                          {event.testCaseName}
                          
                        </div>
                        
                        <div className="d-flex">                                            
                      <div className="">{formateDate(event.createdAt)}</div>
                      <div className="ml-8 ">{event.creator.name}</div>  
                      </div>
                      </div>
                      <div>
                        {testCase?.id === event.id ? (
                          <LeftOutlined
                            className=""
                            onClick={() => dispatch(closeTestCaseDetails())}
                          />
                        ) : (
                          <RightOutlined
                            className=""
                            onClick={() =>
                              dispatch(
                                getTestCaseDataByTCId(
                                  event.id,
                                  suiteId,
                                  projectId
                                )
                              )
                            }
                          />
                        )}
                        </div>
                        </div>
                    </Card>
                  ))
                ) : (
                  <div className="w-full d-flex justify-center align-center">
                    <Typography.Title level={3}>
                      No test case found
                    </Typography.Title>
                  </div>
                )}
              </div>
            </div>
            <div className="pl-20 bg-white">
              {showDetail &&  (
                <CaseDetail testCase={testCase} />
              )}
            </div>
            {testCase?.id && (
              <CaseDetail
                testCase={testCase}
                formatDate={formateDate}
                listCreator={stepListCreator}
                menuClick={handleMenuClick}
                getTags={getTags}
              />
            )}
          </div>
          {(operation === "Add" || operation === "Edit") && (
            <TestCaseModal
              open={operation === "Add" || operation === "Edit"}
              cancelFunc={closeModel}
              mode={operation === "Add" ? "Add" : "Edit"}
              // project={project}
              // suite={suite}
              projectId={projectId}
              suiteId={suiteId}
            />
          )}
          {operation === "Delete" && (
            <DeleteModel
              open={operation === "Delete"}
              cancelFunc={closeModel}
              okFunc={deleteTestCase}
              name="TestCase"
            />
          )}
        </div>
      
    </div>
  );
};

export default React.memo(TestCases);
