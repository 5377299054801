// import React from 'react';
import axios from "axios";
import { warning } from "./shared.service";
// import { redirect } from 'react-router-dom';

const instance = axios.create({
  baseURL: "http://localhost:4002",
});

instance.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem("access-token");
    if (access_token) {
      const parsedData = JSON.parse(access_token);
      // console.log(parsedData);
      const accessToken = parsedData;
      // console.log('logged Access'+accessToken);
      // const accessToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjFiYjI2MzY4YTNkMWExNDg1YmNhNTJiNGY4M2JkYjQ5YjY0ZWM2MmYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcWEtdG9vbC1hYmI1MSIsImF1ZCI6InFhLXRvb2wtYWJiNTEiLCJhdXRoX3RpbWUiOjE2ODQzMTY3MTQsInVzZXJfaWQiOiJ4ZE0zeG5YTnlZUUNEOTZCQ2JLaHFPTk1CWW0yIiwic3ViIjoieGRNM3huWE55WVFDRDk2QkNiS2hxT05NQlltMiIsImlhdCI6MTY4NDMyOTYwMywiZXhwIjoxNjg0MzMzMjAzLCJlbWFpbCI6ImhhcmlzdW5kYXJvZmZpY2lhbDA4QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJoYXJpc3VuZGFyb2ZmaWNpYWwwOEBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.27oKCATv75xhFVUIBnLPeiPJ7nJZXwI0z3gZi-d9PHVXaJ_ki9zfgYtJjmPU7gO0zIH9STD3kXQsVnUYQ3ZiqbbVIgDIUIFeUetnGfvatf4m9W7_x6sHZL2_QkAUduKgd32L_oAD7sW43Ulcw8-yEmyiRUnAHve8pO44-sjCsVOo6gp3LU1j0EnWpdj0pM3pI0m7dc6supnpUgjchw0zdBEUavt7tlrZ-Bvn78iNRxKFi0SIw5mJ9IVirbEhpPDHgrutd_d0Z3Qv4F5h3uI84ZiZeYffNiKgBeM8ifqMdO7MBQa-NMGlON0MvJXLIc5Mf3w0xUOsLVjsrROcbEcSkg';
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 404) {
      warning(error.response.data.error, "error");
      return;
    }
    if (error.response.status === 403) {
      window.location.href = "/";
    }
    if (error.response.status === 401) {
      const refresh_token = localStorage.getItem("refresh-token");
      const parsedData = JSON.parse(refresh_token);
      const refreshToken = parsedData;
      const result = await instance.get(originalRequest.url, {
        headers: { "x-refresh-token": refreshToken },
      });
      console.log(result);
      if (result.status === 200) {
        const headers = result.headers;
        // console.log(headers);
        // console.log(typeof(headers));
        const x_access_token = headers["x-access-token"];
        console.log(x_access_token);
        let userData = localStorage.getItem("access-token");
        localStorage.removeItem("access-token");
        let parsedData = JSON.parse(userData);
        parsedData = x_access_token;
        localStorage.setItem("access-token", JSON.stringify(parsedData));
        return result;
      }
    }
  }
);

export default instance;

// // instance.interceptors.response((res=> {
// //     res.data;
// //     // return data directly
// // }, )

// const result = instance.post(originalRequest.url, {config},)
//         .then(res => {
//           if (res.status === 201) {
//             console.log('inside post request');
//             // Update the Authorization header
//             // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
//             originalRequest.headers['Authorization'] = `Bearer ${res.data.accessToken}`;
//           }
//           return Promise.reject(error);
//         })
//         .catch(err => {
//           // Handle any errors during the refresh token request
//           return Promise.reject(err);
//         });
//       return result;

// // Error

// // Check status code === 401

// // hit the same API with Refersh token

// const getApi = (url, config = {}) => {
//     return instance.get(url);
// };

// ;
