import instance from "../utils/api";
const useragent = window.navigator.userAgent;

export const postApi = async (
  uid,
  firstName,
  email,
  userType,
  refreshToken
) => {
  const user = {
    uid: uid,
    name: firstName,
    email: email,
    role: userType,
  };
  const response = await instance.post(
    `/user?useragent=${useragent}&refresh=${refreshToken}`,
    user
  );
  return response;
};
export const getUserApi = async (id, refreshToken) => {
  // console.log(useragent);
  const response = await instance.get(
    `/user/${id}?useragent=${useragent}&refresh=${refreshToken}`
  );
  return response;
};
export const getAllUserApi = async () => {
  const response = await instance.get("/user");
  return response.data;
};
