// import React, { useState } from "react";
import { HistoryOutlined } from "@ant-design/icons";
import { FiLink2 } from "react-icons/fi";
import { MdOutlineContentCopy, MdOutlineMarkChatUnread } from "react-icons/md";
import { RxDotsHorizontal } from "react-icons/rx";
import { Dropdown } from "antd";
import DropDownMenu from "../DropDown";
import { testCaseOperation } from "../../utils/constants";
import { useSelector } from "react-redux";
import Fileimg from "../../assets/images/icons/image-file.png";
import FilePpt from "../../assets/images/icons/ppt-icon-486.png";
import Filedoc from "../../assets/images/icons/microsoft-word-document-icon-1.jpg";
import FilePdf from "../../assets/images/icons/pdf-icon-png-2058.png";
const CaseDetail = ({ formatDate, listCreator, menuClick, getTags }) => {
  const testCase = useSelector((state) => state?.testCase?.testCase);
  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "application/pdf":
        return <img className="mw-50 w-100" src={FilePdf} alt="PDF" />;
      case "application/msword":
        return <img className="mw-50 w-100" src={Filedoc} alt="DOC" />;
      case "application/vnd.ms-powerpoint":
        return <img className="mw-50 w-100" src={FilePpt} alt="PPT" />;
      case "image/png":
      case "image/jpeg":
        return <img className="mw-50 w-100" src={Fileimg} alt="IMG" />;
    }
  };
  return (
    <div className="w-100 bg-white">
      <div className="d-flex justify-between align-center mt-32">
        <div className="">
          <h2 className="f-26 f-w-600;">{testCase?.testCaseName}</h2>
        </div>
        <div className="d-flex justify-evenly f-20 txt-grey-light w-25">
          <HistoryOutlined />
          <FiLink2 />
          <MdOutlineContentCopy />
          <MdOutlineMarkChatUnread />
          <Dropdown
            overlay={
              <DropDownMenu
                onClick={(e) => menuClick(e)}
                options={testCaseOperation}
              />
            }
            trigger={["click"]}
          >
            <RxDotsHorizontal onClick={getTags} />
          </Dropdown>
        </div>
      </div>
      <div className="border-light-grey radius-10 p-24 mt-24 mb-24">
        <div className="d-flex p-4">
          <div className="f-12 f-w-500 txt-grey-drak">ID Number</div>
          <div className="ml-48">{testCase?.id || "-"}</div>
        </div>
        <div className="d-flex p-4">
          <div className="f-12 f-w-500 txt-grey-drak">Created By</div>
          <div className="ml-45">{testCase?.creator?.name || "-"}</div>
        </div>
        <div className="d-flex p-4">
          <di className="f-12 f-w-500 txt-grey-drak">Date Created</di>
          <div className="ml-31">{formatDate(testCase?.createdAt)}</div>
        </div>
      </div>
      <div className="">
        <div className="f-w-600 f-14 ">Description</div>
        <div className="f-w-500 f-12 txt-grey-drak mt-8 mb-24">
          {testCase?.description}
        </div>
      </div>
      <div className="">
        <div className="f-w-600 f-14">Test Steps</div>
        <div className="f-w-500 f-12 txt-grey-drak mt-8 mb-24 ml-20">
          <ul style={{ listStyleType: "disc" }}>
            {listCreator(testCase?.testSteps).map((step) => {
              return <li>{step}</li>;
            })}
          </ul>
        </div>
      </div>
      <div className="">
        <div className="f-w-600 f-14">Expected Result</div>
        <div className="f-w-500 f-12 txt-grey-drak mt-8 mb-24">
          {testCase?.result}
        </div>
      </div>
      <div className="">
        <div className="f-w-600 f-14">Tags:</div>

        <div className="d-flex f-w-500 f-12 txt-grey-drak mt-8 mb-24">
          {testCase?.tagTestCases?.map((value) => {
            return (
              <div className="mr-26 border-light-grey radius-20 p-4 bg-grey txt-grey-drak">
                {value?.Tagid?.name}
              </div>
            );
          })}
        </div>
      </div>
      <div className="">
        <div className="f-w-600 f-14 ">Attached File:</div>
        <div className="">
          {testCase?.file?.map((value) => {
            return (
              <a
                href={value?.file}
                className="d-flex align-center mt-20 txt-grey-drak"
              >
                {getFileIcon(value.type)} {value?.name}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CaseDetail;
