// fileSlice.js
import { createSlice } from "@reduxjs/toolkit";
export const FILES_KEY = "files";
const filesSlice = createSlice({
  name: FILES_KEY,
  initialState: [],
  reducers: {
    setFiles: (state, action) => {
      return action.payload;
    },
    showFile: (state, action) => {
      state.push(action.payload);
    },
    addedFile: (state, action) => {
      if (state.findIndex((file) => file.name === action.payload.name) === -1) {
        state.push(...action.payload);
      }
    },
    removeFile: (state, action) => {
      const fileIndex = state.findIndex(
        (file) => file.name === action.payload.name
      );
      if (fileIndex !== -1) {
        state.splice(fileIndex, 1);
      }
    },
    deleteFiles: (state) => {
      return [];
    },
  },
});
export const filesReducer = filesSlice.reducer;

export const { setFiles, showFile, removeFile, deleteFiles, addedFile } =
  filesSlice.actions;
