import React from "react";
import { Col, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../../assets/styles/components/dashboard.scss";
import { memberOperation } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { deleteMember, memberStateSelector, updateMember } from "../../reducer/memberSlice";
import { addUser } from "../../reducer/userSlice";
const MemberCard = (props) => {
  const members = useSelector(memberStateSelector);
  const dispatch = useDispatch();
  const handleMemberChange = (user, value) => {
    const modifiedData = {
      id: user.id,
      role: value,
    };
    dispatch(updateMember(modifiedData));
  };
  return (
    <div className="">
      {members?.map((user, index) => {
        return (
          <div className=" user-members-list p-10 mt-10">
            <Col span={24} key={index}>
              <div className="d-flex pl-8 align-center justify-between">
                <p className="d-flex align-center justify-between txt-text-secondary f-w-500 f-14">
                  {user.name}
                </p>
                <div className="d-flex align-center justify-between">
                  <Select
                    // defaultValue="View"
                    value={user?.role}
                    onChange={(value) => handleMemberChange(user, value)}
                    options={memberOperation}
                  />
                  <CloseOutlined className="ml-10" onClick={()=> {
                    dispatch(deleteMember(user));
                    dispatch(addUser(user));
                  }}/>
                </div>
              </div>
            </Col>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(MemberCard);

// const handleMenuClick = (e, item) => {
//   switch (e.key) {
//     case "manageMembers":
//       console.log("Manage Members", item);
//       break;
//     case "rename":
//       // editProject(item);
//       break;
//     case "deleteProject":
//       // setDeleteSuite(item);
//       break;
//     default:
//       break;
//   }
// };
