import React, { useState } from "react";
import { Card, Row, Col, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import PropTypes from "prop-types";
import { router } from "../../utils/routes";
import DropDownMenu from "../DropDown";
import { deleteProjectAPI } from "../../reducer/projectSlice";
import EditProjectModel from "../../components/EditModel/index.js";
import DeleteModel from "../../components/DeleteModel/index.js";
import { messageShow } from "../../utils/shared.service";
import "../../assets/styles/components/dashboard.scss";
import ManageMembersModal from "../ManagemembersModal";
import { getAllUsers } from "../../reducer/userSlice";
import { addMembers } from "../../reducer/memberSlice";
import MemberIcon from "../MemberIcon";
const ProjectCard = (props) => {
  const [project, setProject] = useState({
    search: "",
    projectId: "",
    userId: "",
    openModel: false,
    editModel: false,
    editValue: "",
    editId: "",
    deleteModel: false,
    deleteId: "",
    name: "",
    memberModal: false,
  });
  const user_id = localStorage.getItem("user-id");
  const userId = JSON.parse(user_id);
  const { projects, isLoading, search } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editProject = (values) => {
    const { name, id } = values;
    setProject((prevValue) => ({
      ...prevValue,
      editModel: true,
      editValue: name,
      editId: id,
    }));
  };

  const deleteProject = () => {
    if (project.deleteId !== "") {
      const deleteProject = {
        id: project.deleteId,
        deletedBy: userId,
      };
      // console.log('Render'+deleteProject.id);
      dispatch(deleteProjectAPI(deleteProject, messageShow.DELETE));
      setProject((preValue) => ({
        ...preValue,
        deleteModel: false,
        deleteId: "",
        name: "",
      }));
    }
  };
  const setDeleteSuite = (value) => {
    setProject((preValue) => ({
      ...preValue,
      deleteModel: true,
      deleteId: value.id,
      name: value.name,
    }));
    // console.log('ProjectId: ' + value.id);
  };
  const closeModel = () => {
    setProject({ ...project, editModel: false, editValue: "", editId: "" });
  };

  const projectOperation = [
    {
      key: "manageMembers",
      displayName: "Manage Members",
      value: "Manage Members",
    },
    {
      key: "rename",
      displayName: "Rename",
      value: "Rename",
    },
    {
      key: "deleteProject",
      displayName: "Delete Project",
      value: "Delete Project",
    },
  ];
  const handleEllipseIsClick = (e) => {
    e.stopPropagation();
  };
  const manageMembers = (item) => {
    const filteredMembers = item?.members?.filter((val) => {
      return val.role !== "isOwner";
    });
    const members = filteredMembers?.map((val) => {
      return {
        id: val?.id,
        userId: val?.userId,
        name: val?.user?.name,
        email: val?.user?.email,
        role: val?.role,
      };
    });
    dispatch(addMembers(members));
    setProject((prevValue) => ({
      ...prevValue,
      memberModal: true,
      projectId: item?.id,
      userId: item?.createdBy,
    }));
    dispatch(getAllUsers());
  };
  const handleMenuClick = (e, item) => {
    // dispatch(getParticularTestSuite(item.id))
    switch (e.key) {
      case "manageMembers":
        // console.log("Manage Members", item);
        manageMembers(item);
        break;
      case "rename":
        editProject(item);
        break;
      case "deleteProject":
        setDeleteSuite(item);
        break;
      default:
        break;
    }
  };
  const getProjectCreator = (members) => {
    const owner = members.filter((val) => {
      return val.role === "isOwner";
    });
    return owner[0]?.user?.name;
  };

  const formateDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split(" ");
    const monthAbbreviation = month.substring(0, 3);
    return `${day.substring(0, 2)} ${monthAbbreviation} ${year}`;
  };

  return (
    <div>
      {isLoading && (
        <Loader />
      )}
      <div className="project-cards pl-70 pr-20 pt-20 bg-grey-secondary">
        <Row gutter={[16, 16]}>
          {projects
            .filter((val) =>
              search
                ? val?.name.toLowerCase().includes(search.toLowerCase())
                : val
            )
            .map((item, index) => {
              return (
                <Col span={6} key={index}>
                  <Card
                    className="cursor-pointer"
                    loading={isLoading}
                    actions={[]}
                    onClick={() =>
                      navigate(
                        `${router.projects}/${item.id}/${router.suites}`
                      )
                    }
                  >
                    <div>
                      <div className="d-flex align-center justify-between">
                        <p className="d-flex align-center justify-center txt-light-blue bg-aqua f-16 f-w-600 l-h-22 radius-50 image-42">
                          {item?.name
                            .split(" ")
                            .map((word) => word.charAt(0))
                            .join("")}
                        </p>

                        <div onClick={handleEllipseIsClick}>
                          <Dropdown
                            overlay={
                              <DropDownMenu
                                onClick={(e) => handleMenuClick(e, item)}
                                options={projectOperation}
                              />
                            }
                            trigger={["click"]}
                          >
                            <EllipsisOutlined className="f-24 txt-grey-10" />
                          </Dropdown>
                        </div>
                      </div>

                      {/* Project Name  */}
                      <div className="mt-12">
                        <h4 className="f-16 f-w-600 l-h-22 txt-text-secondary">
                          {item?.name}
                        </h4>
                      </div>

                      {/* Project Details  */}
                      <div className="d-flex justify-between align-center mt-12">
                        <div className="d-flex align-center">
                          {/* Format Date  */}
                          <div>
                            <p className="f-12 f-w-500 l-h-16 txt-grey-light">
                              {formateDate(item?.updatedAt)}
                            </p>
                          </div>
                          <div className="small-dot mx-4"></div>
                          {/* User Name  */}
                          <div>
                            <h4 className="f-12 f-w-500 l-h-16 txt-text-teritary">
                              {getProjectCreator(item.members)?.substring(
                                0,
                                10
                              )}
                            </h4>
                          </div>
                        </div>
                        <div onClick={handleEllipseIsClick}>
                          <MemberIcon members={item?.members} />
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
        </Row>
        <ManageMembersModal
          open={project.memberModal}
          cancelFunc={() => {
            setProject((preValue) => ({
              ...preValue,
              memberModal: false,
            }));
          }}
          projectId={project.projectId}
          userId={project?.userId}
        />
        <EditProjectModel
          open={project.editModel}
          cancelFunc={(values) => closeModel(values)}
          editValues={project}
          okBtnName={"Edit"}
          title={"Edit Project"}
        />
        <DeleteModel
          open={project.deleteModel}
          cancelFunc={() =>
            setProject((preValue) => ({
              ...preValue,
              deleteModel: false,
              deleteId: "",
              name: "",
            }))
          }
          okFunc={deleteProject}
          content={project}
        />
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  projects: PropTypes.array,
  isLoading: PropTypes.bool,
  search: PropTypes.string,
  edit: PropTypes.func,
  deleteProject: PropTypes.func,
};

export default React.memo(ProjectCard);
