import instance from '../utils/api';

export const addTestCaseApi = async (params) => {
  const testcase = {
    testCaseName: params.name,
    description: params.description,
    testSteps:params.steps,
    result: params.expectedResult,
    tags: params.tags,
    file: params.file
  };
  const response = await instance.post(`/project/${params.projectId}/suites/${params.suiteId}/testcase`,testcase);
  return response.data;
}; 

export const getTestCaseApi = async (id,projectId, order) => {
  const response = await instance.get(`/project/${projectId}/suites/${id}/testcase?order=${order}`);
  return response.data?.rows;
};  
export const searchTestCaseApi = async (id,projectId, search) => {
  const response = await instance.get(`/project/${projectId}/suites/${id}/testcase?searchQuery=${search}`);
  return response.data?.rows;
};
export const getParticularTestCase = async (id,projectId,suiteId) => {
    const response = await instance.get(`/project/${projectId}/suites/${suiteId}/testcase/${id}`)
    return response.data;
}

export const getTagsApi = async (id) => {
  const response = await instance.get(`/tags/byproject/${id}`)
  return response.data;
}

export const updateCaseApi = async (params)=>{
  const testcase = {
    testCaseName: params.id.name,
    description: params.id.description,
    testSteps: params.id.steps,
    result: params.id.expectedResult,
    tags: params.id.tags,
    file: params.id.file
  };
  const response = await instance.put(`/project/${params.projectId}/suites/${params.suiteId}/testcase/${params.id.id}`,testcase);
  return response.data;
};
export const deleteCaseApi = async (params) => {
  const response = await instance.delete(`/project/${params.projectId}/suites/${params.suiteId}/testcase/${params.id}`);  
  return response.data;
};