import React from 'react';
import { Menu } from 'antd';
import PropTypes from 'prop-types';

const  DropDownMenu= ({ onClick, options }) => (
  <Menu onClick={onClick}>
    {options.map((option) => (
      <Menu.Item key={option.key}>{option.value}</Menu.Item>
    ))}
  </Menu>
);

DropDownMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DropDownMenu;
