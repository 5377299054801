import { message } from 'antd';
import { isEmpty } from 'lodash';

export const warning = (messageToShow, type) => {
  switch (type) {
    case 'success':
      return message.success({
        content: messageToShow,
      });
    case 'error':
      return message.error(messageToShow);
  }
};

export const formateDate = (date) => {
  const convertedDate = new Date(date);
  return (
    [
      convertedDate.getMonth() + 1,
      convertedDate.getDate(),
      convertedDate.getFullYear(),
    ].join('-') +
    ' ' +
    [
      convertedDate.getHours(),
      convertedDate.getMinutes(),
      convertedDate.getSeconds(),
    ].join(':')
  );
};

export const getLoggedInUserData = () => {
  const userId = localStorage.getItem('user-id');
  if (isEmpty(userId)) {
    return null;
  }

  return JSON.parse(userId);
};

export const messageShow = {
  LOGIN: 'Login successfully',
  EDIT: 'Project updated successfully',
  DELETE: 'Project deleted successfully',
  LOGOUT: 'Logout successfully',
};

export const messageShowSuite = {
  EDIT: 'Test Suite updated successfully',
  DELETE: 'Test Suite deleted successfully',
};
export const messageShowCase = {
  EDIT: 'Test Case updated successfully',
  DELETE: 'Test Case deleted successfully',
};

export const messageFileUpload={
  UPLOAD:'This file type is not allowed  '
}