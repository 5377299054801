import React from "react";
import { Modal, Button, Select } from "antd";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addMembers,
  emptyMembersArray,
  memberStateSelector,
  updateMembersAPI,
} from "../../reducer/memberSlice";
import MemberCard from "../MemberCard";
import { removeUser } from "../../reducer/userSlice";

const ManagemembersModal = (props) => {
  const { cancelFunc, open, projectId, userId } = props;
  const { handleSubmit, reset } = useForm({
    defaultValues: {
      // editProject: editValues.editValue,
    },
  });
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.user?.users);
  const members = useSelector(memberStateSelector);
  // const userOptions = users
  //   .filter((user) => (!members.some((member) => (member.userId === user.id))) && user.id !== userId)
  //   .map((user) => (
  //     <Select.Option key={user.id} value={user.id}>
  //       {user.name}
  //     </Select.Option>
  //   ));

  const excludedUserIds = members.map((member) => member.userId); // Extract the user IDs of existing members

  const userOptions = users.reduce((options, user) => {
    if ((!excludedUserIds.includes(user.id)) && user.id !== userId) {
      options.push(
        <Select.Option key={user.id} value={user.id}>
          {user.name}
        </Select.Option>
      );
    }
    return options;
  }, []);

  const cancelModel = () => {
    cancelFunc();
    reset();
    dispatch(emptyMembersArray());
  };
  const handleSelectedMember = (value) => {
    const userDetail = users?.reduce((foundUser, user) => {
      return user.id === value ? user : foundUser;
    }, null);

    const isUserUnique = !members?.some((member) => member.id === value);

    if (isUserUnique && userDetail) {
      const userWithAccess = {
        userId: userDetail?.id,
        name: userDetail?.name,
        email: userDetail?.email,
        role: "view",
      };
      dispatch(addMembers([userWithAccess]));
    }
    dispatch(removeUser(userDetail));
  };

  const editProject = (data) => {
    const membersToUpdate = {
      projectId: projectId,
      members: members,
    };
    dispatch(updateMembersAPI(membersToUpdate));
    cancelModel();
  };

  const submit = (values) => {
    editProject(values);
  };

  return (
    <form id="manageMembers" onSubmit={handleSubmit(submit)}>
      <div>
        <Modal
          onCancel={cancelModel}
          title="Manage Members"
          centered
          open={open}
          footer={[
            <Button key="cancel" onClick={cancelModel}>
              Cancel
            </Button>,
            <Button
              form="manageMembers"
              key="edit"
              htmlType="submit"
              className="btn-primary"
            >
              Save
            </Button>,
          ]}
        >
          <div>
            <label className="txt-text-teritary">Who Can Access</label>
            <Select
              showSearch
              style={{ marginTop: "0.5rem", width: "452px", height: "40px" }}
              placeholder="Search by name or email"
              allowClear={true}
              defaultOpen={false}
              value={null}
              onChange={(value) => {
                console.log(value);
                handleSelectedMember(value);
              }}
              filterOption={(input, option) => {
                return (
                  (option?.props?.children ?? "")
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
              autoComplete="off"
            >
              {userOptions}
            </Select>
            <div className="txt-text-teritary f-12">
              Editor has access to add, remove, and edit test suites and test
              plans
            </div>
          </div>

          <div className="">
            <MemberCard />
          </div>
        </Modal>
      </div>
    </form>
  );
};

// EditProjectModel.propTypes = {
//   open: PropTypes.bool,
//   editValues: PropTypes.object,
//   cancelFunc: PropTypes.func.isRequired,
//   //   projectId: PropTypes.string,
// };

// EditProjectModel.defaultProps = {
//   open: true,
// };

export default React.memo(ManagemembersModal);
