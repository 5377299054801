import { createSlice } from "@reduxjs/toolkit";

import { warning } from "../utils/shared.service";
import { updateProjectMembersApi } from "../controllers/projects";
import { getProjectData } from "./projectSlice";
// import {
//   addProjectApi,
//   deleteProjectApi,
//   getProjectById,
//   getProjectsApi,
//   searchProjectsApi,
//   updateProjectApi,
// } from "../controllers/projects";
const initialState = {
  membersArray: [],
  member: {},
  error: "",
  showError: false,
  isLoading: false,
};

export const MEMBER_KEY = "member";

export const memberSlice = createSlice({
  name: MEMBER_KEY,
  initialState,

  reducers: {
    updateLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    getMemberDataSuccess: (state, action) => {
      state.membersArray = action.payload;
    },
    getMemberDataFailure: (state, action) => {
      state.isLoading = action.payload;
      state.error = "true";
    },
    addMembers: (state, action) => {
      state.membersArray?.push(...action.payload);
    },
    getParticularMember: (state, action) => {
      state.member = Object.assign(action.payload);
    },
    updateMember: (state, action) => {
      const member = action.payload;
      const index = state.membersArray?.findIndex(
        (user) => (user.id === member.id)
      );
      if (index !== -1) {
        state.membersArray[index] = {
          ...state.membersArray[index],
          role: member.role,
        };
      }
    },
    deleteMember: (state, action) => {
      const userId = action.payload.id;
      const updatedArray = state.membersArray.filter(
        (user) => user.id !== userId
      );
      state.membersArray = updatedArray;
    },
    emptyMembersArray: (state) => {
      state.membersArray = [];
    },
  },
});

export const {
  updateLoader,
  getMemberDataSuccess,
  getMemberDataFailure,
  getParticularMember,
  addMembers,
  deleteMember,
  emptyMembersArray,
  updateMember
} = memberSlice.actions;

export const memberReducer = memberSlice.reducer;

export const memberStateSelector = (state) => state?.member?.membersArray;

export const getMemberData = () => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      //   const modifiedData = await getProjectsApi("ASC");
      //   dispatch(getMemberDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      console.log("errors", err.data);
      dispatch(getMemberDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};
export const searchMembers = (search) => {
  return async (dispatch) => {
    try {
      //   const modifiedData = await searchProjectsApi(search);
      //   dispatch(getMemberDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      console.log("errors", err.data);
      dispatch(getMemberDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};
export const createMember = (params) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      //   await addProjectApi(params);
      //   const modifiedData = await getProjectsApi("DESC");
      //   dispatch(getProjectDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getMemberDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};

export const deleteMembers = (params) => {
  return async (dispatch) => {
    try {
      // console.log('Slice:' + params.id);
      dispatch(updateLoader(true));
      //   await deleteProjectApi(params);
      //   const modifiedData = await getProjectsApi("DESC");
      dispatch(deleteMember(modifiedData));
      dispatch(getMemberDataSuccess(modifiedData));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getMemberDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};

export const updateMembersAPI = (params) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
        await updateProjectMembersApi(params);
        // getProjectData(''); 
      dispatch(getProjectData(''));
      warning('Members Updated SuccessFully..!', "success");
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getMemberDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};

export const getParticularMembers = (id) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoader(true));
      // console.log(id);
      //   const details = await getProjectById(id);
      dispatch(getParticularMember(details));
      dispatch(updateLoader(false));
    } catch (err) {
      dispatch(getMemberDataFailure(err));
      dispatch(updateLoader(false));
    }
  };
};
