import { Avatar } from "antd";
import React, { useState } from "react";

const MemberIcon = ({ members }) => {
  const visibleMembers = members?.slice(2); // Change 6 to 4 if you want to limit to 4 avatars

  return (
    <div>
      {/* <div className="avatar-container"> */}
      <div className="">
        <Avatar.Group maxCount={2} maxPopoverTrigger="none">
          <div className="firsticon">
            <div className="d-flex border-grey index-0 align-center justify-center txt-light-blue bg-aqua f-16 f-w-600 l-h-22 radius-50 image-42">
              {members[0]?.user?.name
                ?.split(" ")
                ?.map((word) => word.charAt(0))
                ?.join("")}
            </div>
          </div>
          {members.length > 1 && (
            <div className="d-flex index-1 align-center justify-center txt-light-blue bg-aqua f-16 f-w-600 l-h-22 radius-50 image-42">
              {members[1]?.user?.name
                ?.split(" ")
                ?.map((word) => word.charAt(0))
                ?.join("")}
            </div>
          )}

          {visibleMembers?.map((member) => {
            return (
              <div className="d-flex align-center justify-center txt-light-blue bg-aqua f-16 f-w-600 l-h-22 radius-50 image-42">
                {member?.user?.name
                  ?.split(" ")
                  ?.map((word) => word.charAt(0))
                  ?.join("")}
              </div>
            );
          })}
        </Avatar.Group>
      </div>
    </div>
  );
};

export default MemberIcon;
