import { createSlice } from "@reduxjs/toolkit";
export const TAGS_KEY = "tags";
const tagsSlice = createSlice({
  name: "tags",
  initialState: [],
  reducers: {
    setTags: (state, action) => {
      return action.payload;
    },
    addTag: (state, action) => {
      state.push(action.payload);
    },
    removeTag: (state, action) => {
      return state.filter(
        (tag) =>
          tag.id !== action.payload.id || tag.name !== action.payload.name
      );
    },

    removeAllTags: (state, action) => {
      return [];
    },
  },
});
export const tagsReducer = tagsSlice.reducer;

export const { setTags, addTag, removeTag, removeAllTags } = tagsSlice.actions;
