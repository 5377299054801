import { router } from './routes';

export const tabList = [
  {
    label: 'Test suites',
    key: router.suites,
  },
  // {
  //   label: 'Test Plans',
  //   key: router.cases,
  // },
];

export const projectOperation = [
  {
    key: 'manageMembers',
    displayName: 'Manage Members',
    value: 'Manage Members',
  },
  {
    key: 'rename',
    displayName: 'Rename',
    value: 'Rename',
  },{
    key: 'deleteProject',
    displayName: 'Delete Project',
    value: 'Delete Project',
  }
];

export const suiteOperation = [
  {
    key: 'rename',
    displayName: 'Rename',
    value: 'Rename',
  },{
    key: 'deleteTestSuite',
    displayName: 'Delete TestSuite',
    value: 'Delete TestSuite',
  }
];

export const memberOperation = [
  {
    key: "edit",
    label: "Edit",
    value: "edit",
  },
  {
    key: "view",
    label: "View",
    value: "view",
  },
];
export const testCaseOperation = [

  {
    key: "rename",
    displayName: "Edit Testcase",
    value: "Edit Testcase",
  },
  {
    key: "deleteTestCase",
    displayName: "Delete Testcase",
    value: "Delete Testcase",
  },
];