import React from 'react';
import { Link } from 'react-router-dom';
import { router } from '../../utils/routes';
import qalogo from "../.././assets/images/maxis-qa-logo.png";
import "../../assets/styles/components/landing.scss";

const LandingPage=()=> {
  return (
		<nav className="sticky top-0">
			<div className="d-flex justify-between align-center px-48 py-16 bg-dark-primary height-70">
				<div className="maxisqa-logo d-flex">
					<img className="object-contain" src={qalogo} alt="Maxis QA Logo  " />
				</div>
				<div>
					<Link
						to="/signup"
						className="f-14 f-w-500 txt-white px-16 py-8 mr-16"
					>
						Sign up
					</Link>
					<Link
						to={router.login}
						className="f-14 f-w-500 txt-white px-16 py-8 border-grey radius-4"
					>
						Login
					</Link>
				</div>
			</div>
		</nav>
	);
}

export default LandingPage;
