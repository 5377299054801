/*eslint linebreak-style: ["error", "windows"]*/
import React, { useState, useEffect } from "react";
import { Input, Button, Drawer, Typography, Select } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import PropsTypes from "prop-types";
import { createProjectAPI } from "../../reducer/projectSlice";
import { useSelector } from "react-redux";
import MemberCard from "../MemberCard";
import { addMembers, emptyMembersArray } from "../../reducer/memberSlice";
import { memberStateSelector } from "../../reducer/memberSlice";
import { removeUser } from "../../reducer/userSlice";
const AddModel = ({ open, title, cancelFunc, okBtnName, editValues }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { editValue } = editValues;
  const {
    control,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.user?.users);
  const members = useSelector(memberStateSelector);
  const userOptions = users.map((user) => (
    <Select.Option key={user.id} value={user.id}>
      {user.name}
    </Select.Option>
  ));

  useEffect(() => {
    if (editValue) {
      setValue("addProject", editValues.editValue);
    }
  }, [editValues]);

  const cancelModel = () => {
    cancelFunc();
    resetField("addProject");
    dispatch(emptyMembersArray());
  };

  const addProject = (data) => {
    let createProject = {
      name: data.addProject,
      members: members,
    };
    dispatch(createProjectAPI(createProject));
    setIsLoading(false);
    cancelModel();
  };
  const handleSelectedMember = (value) => {
    const userDetail = users?.reduce((foundUser, user) => {
      return user.id === value ? user : foundUser;
    }, null);
    
    const isUserUnique = !members?.some((member) => member.id === value);
    
    if (isUserUnique && userDetail) {
      const userWithAccess = { ...userDetail, role: "view" };
      dispatch(addMembers([userWithAccess]));
    }
    dispatch(removeUser(userDetail));
  };
  const submit = (values) => {
    setIsLoading(true);
    addProject(values);
  };

  return (
    <form id="addProject" onSubmit={handleSubmit((data) => submit(data))}>
      <Drawer
        onCancel={cancelModel}
        title={title}
        centered
        open={open}
        width={500}
        onClose={cancelModel}
        footer={[
          <div className="d-flex">
            <div className="mr-20">
            <Button key={"cancel"} onClick={cancelModel}>
            Cancel
          </Button>
            </div>
          <Button
            loading={isLoading}
            form="addProject"
            key={okBtnName}
            htmlType="submit"
            type="primary"
          >
            Add
          </Button>
          </div>
        ]}
      >
        <div className="mb-4">
          <label className="txt-text-teritary">Project Name</label>
          <Controller
            name="addProject"
            control={control}
            rules={{ required: "Please enter project name" }}
            render={({ field }) => (
              <Input
                style={{ marginTop: "0.5rem", width: "452px" }}
                autoFocus
                size="large"
                placeholder="Enter Project Name"
                {...field}
              />
            )}
          />
          <Typography.Text type="danger">
            {errors.addProject?.message}
          </Typography.Text>
        </div>
        <div>
          <label className="txt-text-teritary">Who Can Access</label>
          <Select
            showSearch
            style={{ marginTop: "0.5rem", width: "452px", height: "40px" }}
            placeholder="Search by name or email"
            allowClear={true}
            defaultOpen={false}
            value={null}
            onChange={(value) => {
              handleSelectedMember(value);
            }}
            filterOption={(input, option) => {
              return (
                (option?.props?.children ?? "")
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
            autoComplete="off"
          >
            {userOptions}
          </Select>
          <div className="txt-text-teritary f-12">
            Editor has access to add, remove, and edit test suites and test
            plans
          </div>
        </div>

        <div className="">
          <MemberCard />
        </div>
      </Drawer>
    </form>
  );
};
AddModel.propTypes = {
  open: PropsTypes.bool,
  title: PropsTypes.string.isRequired,
  cancelFunc: PropsTypes.func.isRequired,
  okBtnName: PropsTypes.string.isRequired,
  editValues: PropsTypes.object.isRequired,
  // type: PropsTypes.string.isRequired,
  projectId: PropsTypes.string,
};

AddModel.defaultProps = {
  open: true,
};

export default React.memo(AddModel);
