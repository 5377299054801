/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { Typography, Divider, Card } from 'antd';

const Activity = () => {
  return (
    <div>
      <Typography.Title level={5}>Project Activity</Typography.Title>
      <Divider />
      <div className='d-flex flex-column g-4'>
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <Card key={index}>
              <div className='d-flex align-center'>
                <div className='flex-1'>
                  <Typography.Title level={5}>Today</Typography.Title>
                </div>
                <div className='flex-5'>
                  <Typography level={5}>
                    {index % 2 === 0
                      ? 'jaxemi5121 kaftee.com created test suite: Register.'
                      : 'jaxemi5121 kaftee.com created test suite: Login.'}
                  </Typography>
                </div>
              </div>
            </Card>
          ))}
      </div>
    </div>
  );
};

export default React.memo(Activity);
