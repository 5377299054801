/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Login, SignUp, Projects, ProjectDetails, Suites} from './pages';
import { router } from './utils/routes';
import Dashboard from './pages/Dashboard/index.js';
import ProtectedRoute from './auth/auth';
import 'antd/dist/antd.min.css';
import LandingPage from './pages/Landing/index.js';
import useProtectedRoute from './customHooks/protectedRoute';
import TestCases from './pages/TestCases';
function App() {
  const result = useProtectedRoute();
  return (
    <Router>
      <Routes>
        {/* <Route element={result ? <Navigate to={router.login}/> : <SignUp />}/> */}
        <Route path={router.dashboard} element={result ? <Navigate to={router.login}/> : <Dashboard />} />
        <Route path={router.default} element={result ? <Navigate to={router.login}/> :<LandingPage />} />
        <Route path={router.login} element={<Login userType="user" />} />
        <Route path={router.admin} element={<Login userType="admin" />} />
        <Route path={router.signup} element={<SignUp userType="user" />} />
        <Route
          path={router.adminSignup}
          element={<SignUp userType="admin" />}
        />
        {/* </Route> */}
        <Route element = {<ProtectedRoute result = {result}/>} >  
          <Route >
            <Route path={`${router.projects}/*`}>
              <Route index element={<Projects />} />
              <Route
                path={`${router.projectDetails}/${router.overview}`}
                element={<ProjectDetails />}
              />
              <Route
                path={`${router.projectDetails}/${router.suites}`}
                element={<Suites />}
              />
              <Route
                path={`${router.projectDetails}/${router.suites}/${router.suiteDetails}/${router.cases}`}
                element={<TestCases />}
              />
            </Route>
          </Route>
        </Route>

        <Route path={'*'} element={<div>Page not found</div>} />
      </Routes>
    </Router>
  );
}

export default React.memo(App);
