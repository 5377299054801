import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBUH7dnt4LGtBa2dOeFQGVZ3GtoNVJJTUk',
  authDomain: 'qa-tool-abb51.firebaseapp.com',
  projectId: 'qa-tool-abb51',
  storageBucket: 'qa-tool-abb51.appspot.com',
  messagingSenderId: '612910827367',
  appId: '1:612910827367:web:c1a4a717bec459ec7351c2',
  measurementId: 'G-751ZCXWMWE',
};

export const initializeFireBase = () => {
  return initializeApp(firebaseConfig);
};

export const initializeStore = () => {
  return getFirestore();
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
