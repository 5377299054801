// passwordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showPassword: false,
};
export const PASSWORD_KEY ='password';
export const passwordSlice = createSlice({
  name: PASSWORD_KEY,
  initialState,
  reducers: {
    toggleShowPassword(state) {
      state.showPassword = !state.showPassword;
    },
  },
});

export const { toggleShowPassword } = passwordSlice.actions;

export default passwordSlice.reducer;
