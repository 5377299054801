import React, { useState } from "react";
import { Card, Row, Col, Typography, Dropdown } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteModel } from "../../components";
import { EllipsisOutlined } from "@ant-design/icons";
import Loader from "../Loader";
import { deleteTestSuiteAPI } from "../../reducer/testSuitesSlice";
import { suiteOperation } from "../../utils/constants";
import { router } from "../../utils/routes";
import { messageShowSuite } from "../../utils/shared.service";
import DropDownMenu from "../../components/DropDown";
import AddSuitModel from "../TestSuiteAddModal/suitAddModel";
const SuiteCard = () => {
  const [suites, setSuites] = useState({
    search: "",
    openModel: false,
    editValue: "",
    editId: "",
    deleteModel: false,
    deleteId: "",
    testSuite: "",
  });
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.testSuite?.isLoading);
  const navigate = useNavigate();
  const params = useParams();
  const getTestSuiteList = useSelector(
    (state) => state?.testSuite?.testSuitesArr
  );
  const projectId = params.projectId;

  const closeModel = () => {
    setSuites((prevValue) => ({ ...prevValue, openModel: false, editId: "" }));
  };

  const editSuite = (values) => {
    setSuites((prevValue) => ({
      ...prevValue,
      openModel: true,
      editValue: values.name,
      editId: values.id,
    }));
  };

  const setDeleteSuite = (value) => {
    const { id } = value;
    setSuites((preValue) => ({
      ...preValue,
      deleteModel: true,
      deleteId: id,
      testSuite: value,
    }));
  };
  const deleteSuite = () => {
    if (suites.deleteId !== "") {
      const deleteTestSuite = {
        id: suites.deleteId,
      };
      dispatch(
        deleteTestSuiteAPI(deleteTestSuite, projectId, messageShowSuite.DELETE)
      );
      setSuites((preValue) => ({
        ...preValue,
        deleteModel: false,
        deleteId: "",
        testSuite: {},
      }));
    }
  };
  const handleMenuClickTestSuite = (e, event) => {
    switch (e.key) {
      case "rename":
        // console.log(event.name);
        editSuite(event);
        break;
      case "deleteTestSuite":
        setDeleteSuite(event);
        break;
      default:
        break;
    }
  };
  const handleEllipseIsClick = (e) => {
    e.stopPropagation();
  };
  const formateDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = new Date(dateString);
    // console.log( 'Hello ' + dateString);
    const formattedDate = date.toLocaleDateString("en-US", options);
    // console.log('Hello ' + formattedDate);
    const [month, day, year] = formattedDate.split(" ");
    const monthAbbreviation = month.substring(0, 3);
    return `${day.substring(0, 2)} ${monthAbbreviation} ${year}`;
  };

  return (
    <div>
        {/* Tab Contents  */}
        {isLoading && (
          <Loader />
        )}
        <div>
          <div>
            <Row gutter={[16, 16]}>
              {getTestSuiteList?.length ? (
                getTestSuiteList
                  .filter((val) =>
                    suites.search
                      ? val.name
                          .toLowerCase()
                          .includes(suites.search.toLowerCase())
                      : val
                  )
                  .map((event, index) => (
                    <Col span={6} key={index}>
                      <Card
                        className="cursor-pointer"
                        loading={isLoading}
                        actions={[]}
                        key={index}
                        onClick={() => {
                          navigate(
                            `${router.projects}/${projectId}/${router.suites}/${event.id}/${router.cases}`
                          );
                        }}
                      >
                        <div>
                          <div className="d-flex align-center justify-between">
                            <h4 className="f-16 f-w-500 l-h-22 txt-text-secondary">
                              {event.name}
                            </h4>
                            <div onClick={handleEllipseIsClick}>
                              <Dropdown
                                overlay={
                                  <DropDownMenu
                                    onClick={(e) =>
                                      handleMenuClickTestSuite(e, event)
                                    }
                                    options={suiteOperation}
                                  />
                                }
                                trigger={["click"]}
                              >
                                <EllipsisOutlined className="f-20" />
                              </Dropdown>
                            </div>
                          </div>
                          <div className="d-flex align-center mt-6">
                            {/* Format Date  */}
                            <div>
                              <p className="f-12 f-w-500 l-h-16 txt-grey-light">
                                {formateDate(event.createdAt)}
                              </p>
                            </div>
                            <div className="small-dot mx-4"></div>
                            {/* User Name  */}
                            <div>
                              <h4 className="f-12 f-w-500 l-h-16 txt-text-teritary">
                                {event.creator.name}
                              </h4>
                            </div>
                          </div>
                          <div className="mt-20">
                            {event?.testcases?.length > 1 ? (
                              <p className="py-4 px-8 d-inline-block radius-4 bg-sky-blue txt-light-blue-14">
                                {event?.testcases?.length} testcases
                              </p>
                            ) : (
                              <p className="py-4 px-8 d-inline-block radius-4 bg-sky-blue txt-light-blue-14">
                                {event?.testcases?.length} testcase
                              </p>
                            )}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))
              ) : (
                <div className="no-found-data w-100 d-flex justify-center align-center">
                  <Typography.Title level={3}>
                    No test suite found
                  </Typography.Title>
                </div>
              )}
            </Row>
          </div>
          {suites.openModel && (
            <AddSuitModel
              open={suites.openModel}
              editValues={suites}
              cancelFunc={(values) => closeModel(values)}
              mode={suites.editId === "" ? "Add" : "Edit"}
              projectId={projectId}
            />
          )}
          <DeleteModel
            open={suites.deleteModel}
            cancelFunc={() =>
              setSuites((preValue) => ({
                ...preValue,
                deleteModel: false,
                deleteId: "",
              }))
            }
            okFunc={deleteSuite}
            content={suites.testSuite}
          />
        </div>


    </div>
  );
};

export default SuiteCard;
