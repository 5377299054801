/*eslint linebreak-style: ["error", "windows"]*/
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { warning } from '../utils/shared.service';
import { initializeFireBase, initializeStore } from '../config/firebase';

// import { postApi } from '../controllers/user';

// import axios from 'axios'
// const apiUrl = `http://localhost:4002/user`;

const getAuthInstance = (config) => {
  return getAuth(config);
};

export const login = async (email, password) => {
  try {
    const firebaseApp = initializeFireBase();
    const auth = getAuthInstance(firebaseApp);
    const user = await signInWithEmailAndPassword(auth, email, password);
    return user?.user;
  } catch (error) {
    throw error;

  }
};

export const signUp = async (firstName, email, password) => {
  try {
    const firebaseApp = initializeFireBase();
    const auth = getAuthInstance(firebaseApp);
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential?.user;
  } catch (error) {
    throw error;
  }
};

//Google Signin...
export const signInWithGoogle = async() => {
  try {
    const firebaseApp = initializeFireBase();
    const auth = getAuthInstance(firebaseApp);
    const provider = new GoogleAuthProvider();
    const user = await signInWithPopup(auth, provider);
    console.log(user);
    return user;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (data) => {
  const db = initializeStore();
  const user = await addDoc(collection(db, 'users'), data);
  return user?.id;
};

export const signOutUser = async () => {
  try {
    // eslint-disable-next-line no-undef
    const auth = getAuthInstance(firebaseApp);
    const user = await signOut(auth);
    return user?.user;
  } catch (error) {
    throw error;
  }
};

