import instance from "../utils/api";

export const addProjectApi = async (params) => {
  const response = await instance.post("/project", params);
  console.log(response.data);
  return response.data;
};

export const getProjectsApi = async (order) => {
  const response = await instance.get(`/project?order=${order}`);
  //   console.log(response.data);
  return response.data;
};
export const getProjectById = async (id) => {
  const response = await instance.get(`/project/${id}`);
  return response.data;
};

export const searchProjectsApi = async (params) => {
  const response = await instance.get(
    `/project?order=${"ASC"}&search=${params}`
  );
  //   console.log(response.data);
  return response.data;
};

export const updateProjectApi = async (params) => {
  console.log(params);
  const user = {
    name: params.name,
  };
  const response = await instance.put(`/project/${params.id}`, user);
  return response.data;
};

export const deleteProjectApi = async (params) => {
  // console.log(params.projectId);
  const response = await instance.delete(`/project/${params.id}`);
  // console.log(response.data);
  return response
};
export const getProjectSearchApi = async (params) => {
  console.log(params);
  const response = await instance.delete(`/project/?search=${params}`);
  return response.data;
};

export const updateProjectMembersApi = async (params) => {
  console.log(params);
  const response = await instance.put(
    `/project/members/${params?.projectId}`,
    {members: [...params?.members]}
  );
  return response.data;
};
