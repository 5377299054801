// FileUploader.js
import { storage } from "../../config/firebase";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { removeFile, deleteFiles, addedFile, showFile } from "../../reducer/fileSlice";
import { RxCross2 } from "react-icons/rx";
import { Progress } from "antd";
import Fileimg from "../../assets/images/icons/image-file.png";
import FilePpt from "../../assets/images/icons/ppt-icon-486.png";
import Filedoc from "../../assets/images/icons/microsoft-word-document-icon-1.jpg";
import FilePdf from "../../assets/images/icons/pdf-icon-png-2058.png";
import "../../assets/styles/components/fileUpload.scss";
import { messageFileUpload, warning } from "../../utils/shared.service";

const FileUploader = () => {
  const dispatch = useDispatch();
  const [percent, setPercent] = useState(0);
  const file = useSelector((state) => state.files);

  const wrapperRef = useRef(null);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const fileType = e.target.files;
    Object.values(fileType).forEach((file) => {
      if (
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === "application/vnd.ms-powerpoint" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        e.preventDefault();
        dispatch(showFile(file));
      } else {
        warning(`${messageFileUpload.UPLOAD}"${ file.type} "`,'error')
      }
    });
  };

  const fileRemove = (selectedFile) => {
    dispatch(removeFile(selectedFile));
  };

  const urlChecker = (currentElement) => {
    return typeof currentElement?.file === "string";
  };

  const handleUpload = async () => {
    if (file.every(urlChecker)) {
      return;
    }
    if (!file?.length > 0) {
      alert("Please upload a file first!");
      return;
    }

    try {
      const uploadedFiles = await Promise.all(
        file.map((val) => {
          if (!val?.file) {
            const uuid = uuidv4();
            const storageRef = ref(storage, `/files/${uuid}-${val?.name}`);
            const uploadTask = uploadBytesResumable(storageRef, val);

            return new Promise((resolve, reject) => {
              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                  setPercent(percent);
                },
                (err) => reject(err),
                () => {
                  getDownloadURL(uploadTask.snapshot.ref)
                    .then((url) => {
                      const updatedVal = {
                        name: val.name,
                        file: url,
                        size: val.size,
                        type: val.type,
                      };
                      resolve(updatedVal);
                    })
                    .catch((err) => reject(err));
                }
              );
            });
          } else {
            return val;
          }
        })
      );

      if (uploadedFiles?.length > 0) {
        dispatch(deleteFiles());
        dispatch(addedFile(uploadedFiles));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (file.length > 0) {
      handleUpload();
    }
  }, [file]);


  const getFilePrefix = (fileType) => {
    switch (fileType) {
      case "application/pdf":
        return <img src={FilePdf} alt="PDF" />;
      case "application/msword":
        return <img src={Filedoc} alt="DOC" />;
      case "application/vnd.ms-powerpoint":
        return <img src={FilePpt} alt="PPT" />;
      case "image/png":
      case "image/jpeg":
        return <img src={Fileimg} alt="IMG" />;
    }
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <p>
            <Link>Browse file</Link> or drag here{" "}
          </p>
        </div>
        <input type="file" multiple={true} onChange={onFileDrop} />
      </div>
      {file?.length > 0 ? (
        <div className="drop-file-preview">
          {file?.map((item, index) => (
            <div key={index} className="drop-file-preview__item index-0">
              <div className="drop-file-preview_item_info">
                <div className="f-16 d-flex align-center">
                  <span>{getFilePrefix(item.type)}</span> - {item?.name} (
                  {Math.floor(item?.size / 1024)}kb)
                </div>
                {percent !== 100 && !item?.file ? (
                  <Progress strokeLinecap="butt" percent={percent} />
                ) : null}
              </div>
              <span
                className="drop-file-preview_item_del index-1"
                onClick={() => fileRemove(item)}
              >
                <RxCross2 />
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default FileUploader;
